import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Typography, Table, TableBody, TableCell, TableRow, Paper } from '@mui/material';
import loanDetailStore from '../store';

const ConsolidatedBalanceSheetProforma = observer(() => {
    const { loanData } = loanDetailStore;
    const proformaData = loanData?.balanceSheetProforma || {};
    const consolidatedData = loanData?.consolidatedBalance || {};

    const formatCurrency = (value) => {
        return value ? `$${parseFloat(value).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}` : 'N/A';
    };

    const renderSection = (title, items) => (
        <Box mb={3}>
            <Typography variant="h6">{title}</Typography>
            <Table size="small">
                <TableBody>
                    {items.map((item, index) => (
                        <TableRow key={index}>
                            <TableCell>{item.label}</TableCell>
                            <TableCell align="right">{formatCurrency(consolidatedData[item.field])}</TableCell>
                            <TableCell align="right">{formatCurrency(proformaData[item.field + 'Change'])}</TableCell>
                            <TableCell align="right">{formatCurrency(
                                parseFloat(consolidatedData[item.field] || 0) + parseFloat(proformaData[item.field + 'Change'] || 0)
                            )}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Box>
    );

    const currentAssets = [
        { label: 'Cash', field: 'cash' },
        { label: 'Savings', field: 'savings' },
        { label: 'Marketable Bonds & Securities', field: 'marketableBondsAndSecurities' },
        { label: 'Cash Value Life Insurance', field: 'cashValueLifeInsurance' },
        { label: 'Prepaid Expenses & Supplies', field: 'prepaidExpensesAndSupplies' },
        { label: 'Accounts Receivable (D)', field: 'accountsReceivableScheduleD' },
        { label: 'Current Portion Notes Receivable (E)', field: 'currentPortionNotesReceivableScheduleE' },
        { label: 'Harvested Crops (G)', field: 'harvestedCropsScheduleG' },
        { label: 'Growing Crops (at cost) (F)', field: 'growingCropsAtCostScheduleF' },
        { label: 'Livestock held for sale (at market) (I)', field: 'livestockHeldForSaleAtMarketScheduleI' },
    ];

    const currentLiabilities = [
        { label: 'Current Debt - Mkt. LS, Crop Credit (C)', field: 'currentDebtMatLsCropCredit' },
        { label: 'Income Taxes Payable', field: 'incomeTaxPayable' },
        { label: 'Real Estate Taxes Due', field: 'realEstateTaxesDue' },
        { label: 'Rents Due', field: 'rentsDue' },
        { label: 'Accrued Interest (A,B,C)', field: 'accruedInterest' },
        { label: 'Current Mat. of Non-Curr. Debt (A,B)', field: 'currentMaturityOfNonCurrentDebt' },
    ];

    const intermediateAssets = [
        { label: 'Livestock - Breeding (Schedule H)', field: 'livestockBreeding' },
        { label: 'Machinery and Vehicles (Schedule K)', field: 'machineryAndVehicles' },
        { label: 'Notes and Ctr Receivable (Schedule E)', field: 'notesAndContractsReceivable' },
        { label: 'Less Current Portion (Schedule E)', field: 'lessCurrentPortionScheduleE' },
    ];

    const intermediateLiabilities = [
        { label: 'Chattel Debt/Notes Payable (Sch B)', field: 'chattelDebtAndNotesPayable' },
        { label: 'Less Current Portion (Schedule B)', field: 'lessCurrentPortionScheduleB' },
    ];

    const longTermAssets = [
        { label: 'Real Estate Owned (Schedule A)', field: 'realEstateOwned' },
    ];

    const longTermLiabilities = [
        { label: 'Real Estate Mortgages (Schedule A)', field: 'realEstateMortgages' },
        { label: 'Less Current Portion (Schedule A)', field: 'lessCurrentPortionScheduleA' },
    ];

    return (
        <Paper elevation={3} sx={{ p: 3, m: 2 }}>
            <Typography variant="h5" gutterBottom>Consolidated Balance Sheet Proforma Report</Typography>
            <Typography variant="subtitle1" gutterBottom>Date: {new Date().toLocaleDateString()}</Typography>

            {renderSection('Current Assets', currentAssets)}
            {renderSection('Current Liabilities', currentLiabilities)}
            {renderSection('Intermediate Assets', intermediateAssets)}
            {renderSection('Intermediate Liabilities', intermediateLiabilities)}
            {renderSection('Long Term Assets', longTermAssets)}
            {renderSection('Long Term Liabilities', longTermLiabilities)}

            <Box mb={3}>
                <Typography variant="h6">Summary</Typography>
                <Table size="small">
                    <TableBody>
                        <TableRow>
                            <TableCell>Total Assets</TableCell>
                            <TableCell align="right">{formatCurrency(consolidatedData.totalAssets)}</TableCell>
                            <TableCell align="right">{formatCurrency(proformaData.totalAssetsChange)}</TableCell>
                            <TableCell align="right">{formatCurrency(
                                parseFloat(consolidatedData.totalAssets || 0) + parseFloat(proformaData.totalAssetsChange || 0)
                            )}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Total Liabilities</TableCell>
                            <TableCell align="right">{formatCurrency(consolidatedData.totalLiabilities)}</TableCell>
                            <TableCell align="right">{formatCurrency(proformaData.totalLiabilitiesChange)}</TableCell>
                            <TableCell align="right">{formatCurrency(
                                parseFloat(consolidatedData.totalLiabilities || 0) + parseFloat(proformaData.totalLiabilitiesChange || 0)
                            )}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Net Worth</TableCell>
                            <TableCell align="right">{formatCurrency(consolidatedData.netWorth)}</TableCell>
                            <TableCell align="right">{formatCurrency(proformaData.netWorthChange)}</TableCell>
                            <TableCell align="right">{formatCurrency(
                                parseFloat(consolidatedData.netWorth || 0) + parseFloat(proformaData.netWorthChange || 0)
                            )}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Box>
        </Paper>
    );
});

export default ConsolidatedBalanceSheetProforma;