import React, { useRef, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Typography, Paper, Button, Table, TableHead, TableRow, TableCell, TableBody, Divider } from '@mui/material';
import { useReactToPrint } from 'react-to-print';
import loanDetailStore from '../store';
import ConsolidatedBSAProformaSchedules from './ConsolidatedBSAProformaSchedules';
import IndividualBorrowerIncomeStatements from './IndividualBorrowerIncomeStatements';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../firebase';
import IndividualBorrowerBalanceSheets from "./IndividualBorrowerBalanceSheets";
import IndividualBorrowerBSASchedules from "./IndividualBorrowerBSASchedules";
import SubsidiaryAnalysisReport from "./SubsidiaryAnalysisReport";
import ConsolidatedBalanceSheet from "./ConsolidatedBalanceSheet";
import ConsolidatedBSAReport from "./ConsolidatedBSAReport";
import ConsolidatedIncomeExpenseProforma from "./ConsolidatedIncomeExpenseProforma";
import ConsolidatedBalanceSheetProforma from "./ConsolidatedBalanceSheetProforma";
import ConsolidatedBSAProforma from "./ConolidatedBSAProforma";

const LoanSummaryReport = observer(() => {
    const { loanData, borrowers } = loanDetailStore;
    const componentRef = useRef();
    const [originatorData, setOriginatorData] = useState(null);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: `Loan_Summary_Report_${loanData.id}`,
        onAfterPrint: () => alert('Print PDF option was closed')
    });

    useEffect(() => {
        const fetchOriginatorData = async () => {
            if (loanData?.lending_organization) {
                const organizationRef = doc(db, 'organizations', loanData.lending_organization.id);
                const organizationDoc = await getDoc(organizationRef);
                if (organizationDoc.exists()) {
                    setOriginatorData(organizationDoc.data());
                }
            }
        };

        fetchOriginatorData();
    }, [loanData?.lending_organization]);

    const SellerInformation = () => {
        return (
            <Box mb={3}>
                <Typography variant="h6">Seller Information:</Typography>
                <Typography>Seller ID/Name: {originatorData ? `${originatorData.name}` : 'N/A'}</Typography>
                <Typography>Address: {originatorData?.street_address || 'N/A'}</Typography>
                <Typography>City, State, Zip: {`${originatorData?.city || ''}, ${originatorData?.state || ''} ${originatorData?.zip || ''}`}</Typography>
                <Typography>Phone: {originatorData?.phone_number || 'N/A'}</Typography>
                <Typography>Email: {originatorData?.email || 'N/A'}</Typography>
            </Box>
        );
    };

    const BorrowerInformation = () => {
        const maskTaxId = (taxId) => taxId ? `*****${taxId.slice(-4)}` : 'N/A';

        const BorrowerSection = ({ borrower, isPrincipal }) => (
            <Box mb={3}>
                <Typography variant="h6">{isPrincipal ? "Principal Borrower" : "Co-Borrower"}</Typography>
                <Typography>Name: {borrower.type === 'Entity' ? borrower.entityName : `${borrower.firstName} ${borrower.lastName}`}</Typography>
                <Typography>Borrower Type: {borrower.type || 'N/A'}</Typography>
                <Typography>Billing Address: {borrower.address || 'N/A'}</Typography>
                <Typography>City, State, Zip: {`${borrower.city || ''}, ${borrower.state || ''} ${borrower.zip || ''}`}</Typography>
                <Typography>US Citizen or Permanent Alien: {borrower.nonUSCitizen ? 'No' : 'Yes'}</Typography>
                <Typography>TIN/SSN: {maskTaxId(borrower.taxId)}</Typography>
                <Typography>Business Phone: {borrower.phone || 'N/A'}</Typography>
                <Typography>Home Phone: {borrower.homePhone || 'N/A'}</Typography>
                <Typography>Fax Number: {borrower.fax || 'N/A'}</Typography>
                {borrower.type === 'Individual' && (
                    <Typography>Date of Birth: {borrower.dob || 'N/A'}</Typography>
                )}
                {borrower.type === 'Entity' && (
                    <Typography>Business Structure: {borrower.businessStructure || 'N/A'}</Typography>
                )}
                {borrower.cbrScore && (
                    <Typography>CBR Score: {borrower.cbrScore}</Typography>
                )}
            </Box>
        );

        const principalBorrower = borrowers.find(b => b.is_principal) || {};
        const coBorrowers = borrowers.filter(b => !b.is_principal);

        return (
            <Box mb={3}>
                <Typography variant="h5" gutterBottom>Borrower Information</Typography>
                <BorrowerSection borrower={principalBorrower} isPrincipal={true} />
                {coBorrowers.map((borrower, index) => (
                    <React.Fragment key={index}>
                        <Divider sx={{ my: 2 }} />
                        <BorrowerSection borrower={borrower} isPrincipal={false} />
                    </React.Fragment>
                ))}
                <Divider sx={{ my: 2 }} />
                <Typography>Do Applicants Have Other Related Loans?: {loanData?.application?.otherLoansWithOriginator ? 'Yes' : 'No'}</Typography>
                <Typography>Are there guarantees on this loan?: {loanData?.application?.guaranteesOnLoan ? 'Yes' : 'No'}</Typography>
                <Typography>Are there Co-Applicants for this loan?: {loanData?.application?.coApplicantsOnLoan ? 'Yes' : 'No'}</Typography>
            </Box>
        );
    };

    const LoanRequested = () => {
        const loanTerms = loanData?.application?.loanTerms || {};
        const getAdjustableFrequency = (product) => {
            const match = product?.match(/(\d+)-Yr/);
            return match ? match[1] : 'N/A';
        };

        return (
            <Box mb={3}>
                <Typography variant="h6">Loan Requested:</Typography>
                <Typography>Principal: ${loanTerms.loanAmountRequested?.toLocaleString() || 'N/A'}</Typography>
                <Typography>Billing Frequency: {loanTerms.loan_payment_frequency || 'N/A'}</Typography>
                <Typography>Description: {loanTerms.loan_product || 'N/A'}</Typography>
                <Typography>Max Rate Req.(%): {loanTerms.maxInterestRate?.replace('%', '') || 'N/A'}</Typography>
                <Typography>Term: {loanTerms.loan_amortization_years || 'N/A'}</Typography>
                <Typography>Amortization: {loanTerms.loan_amortization_years || 'N/A'}</Typography>
                <Typography>Payments per year: {loanTerms.loan_payment_frequency === 'Monthly' ? '12' :
                    loanTerms.loan_payment_frequency === 'Semi-Annual' ? '2' :
                        loanTerms.loan_payment_frequency === 'Annual' ? '1' : 'N/A'}</Typography>
                <Typography>(Fixed, ARM, VRM): {loanTerms.loan_product?.includes('Fixed') ? 'Fixed' :
                    loanTerms.loan_product?.includes('ARM') ? 'ARM' : 'VRM'}</Typography>
                <Typography>Adjustable Frequency(Years): {getAdjustableFrequency(loanTerms.loan_product)}</Typography>
            </Box>
        );
    };

    const UseOfFunds = () => {
        const useOfFunds = loanData?.application?.useOfFunds || [];
        const totalAmount = useOfFunds.reduce((sum, item) => sum + (parseFloat(item.amount) || 0), 0);

        return (
            <Box mb={3}>
                <Typography variant="h6">Use of Funds:</Typography>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Loan Purpose</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell align="right">Amount($)</TableCell>
                            <TableCell align="right">(%)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {useOfFunds.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell>{item.loanPurpose}</TableCell>
                                <TableCell>{item.description}</TableCell>
                                <TableCell align="right">${parseFloat(item.amount).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</TableCell>
                                <TableCell align="right">
                                    {((parseFloat(item.amount) / totalAmount) * 100).toFixed(2)}%
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell colSpan={2}><strong>Total:</strong></TableCell>
                            <TableCell align="right"><strong>${totalAmount.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</strong></TableCell>
                            <TableCell align="right"><strong>100.00%</strong></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Box>
        );
    };

    const RealEstateSecurity = () => {
        const security = loanData?.application?.security || {};
        const loanSummary = loanData?.loanSummary || {};

        return (
            <Box mb={3}>
                <Typography variant="h6">Real Estate Security:</Typography>
                <Typography>Total Acres: {security.acres || 'N/A'}</Typography>
                <Typography>Tillable: {loanSummary.tillable || 'N/A'}</Typography>
                <Typography>Irrigated Acres: {loanSummary.irrigatedAcres || 'N/A'}</Typography>
                <Typography>County: {security.county || 'N/A'}</Typography>
                <Typography>State: {security.state || 'N/A'}</Typography>
                <Typography>Value: ${parseFloat(loanSummary.value).toLocaleString() || 'N/A'}</Typography>
                <Typography>Net Property Income: ${parseFloat(loanSummary.netPropertyIncome).toLocaleString() || 'N/A'}</Typography>

                <Typography variant="subtitle1" mt={2}>COMMODITIES GROWN ON SEC.</Typography>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Commodity Type</TableCell>
                            <TableCell align="right">% of Total</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loanSummary.commodities?.map((commodity, index) => (
                            <TableRow key={index}>
                                <TableCell>{commodity.type}</TableCell>
                                <TableCell align="right">{commodity.percentage}%</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
        );
    };

    const BalanceSheetTrendAnalysis = () => {
        const balanceSheetTrend = loanData?.loanSummary?.balanceSheetTrend || {};
        const years = ['year1', 'year2', 'year3', 'year4', 'current', 'proForma'];

        const formatCurrency = (value) => {
            return value ? `$${parseFloat(value).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}` : 'N/A';
        };

        const formatRatio = (value) => {
            return value ? parseFloat(value).toFixed(2) : 'N/A';
        };

        const calculateNetWorth = (year) => {
            const totalAssets = (parseFloat(balanceSheetTrend[year]?.currentAssets) || 0) +
                (parseFloat(balanceSheetTrend[year]?.otherNonCurrentAssets) || 0) +
                (parseFloat(balanceSheetTrend[year]?.realEstate) || 0);
            const totalLiabilities = (parseFloat(balanceSheetTrend[year]?.currentLiabilities) || 0) +
                (parseFloat(balanceSheetTrend[year]?.otherNonCurrentLiabilities) || 0) +
                (parseFloat(balanceSheetTrend[year]?.realEstateLiabilities) || 0);
            return totalAssets - totalLiabilities;
        };

        const calculateChangeInNetWorth = (currentYear, previousYear) => {
            if (currentYear === 'year1') return 'N/A';
            const currentNetWorth = calculateNetWorth(currentYear);
            const previousNetWorth = calculateNetWorth(previousYear);
            return currentNetWorth - previousNetWorth;
        };

        return (
            <Box mb={3}>
                <Typography variant="h6">Balance Sheet Trend Analysis</Typography>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Year</TableCell>
                            {years.map((year) => (
                                <TableCell key={year} align="right">
                                    {year === 'current' ? '4/1/2024' :
                                        year === 'proForma' ? 'Pro-Forma' :
                                            balanceSheetTrend[year]?.date}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {[
                            { label: 'Current Assets', field: 'currentAssets' },
                            { label: 'Other N/Curr Ass.', field: 'otherNonCurrentAssets' },
                            { label: 'Real Estate', field: 'realEstate' },
                            { label: 'Total Assets', calculate: (year) =>
                                    (parseFloat(balanceSheetTrend[year]?.currentAssets) || 0) +
                                    (parseFloat(balanceSheetTrend[year]?.otherNonCurrentAssets) || 0) +
                                    (parseFloat(balanceSheetTrend[year]?.realEstate) || 0)
                            },
                            { label: 'Current Liabilities', field: 'currentLiabilities' },
                            { label: 'Other N/Curr Liab.', field: 'otherNonCurrentLiabilities' },
                            { label: 'Real Estate Liabilities', field: 'realEstateLiabilities' },
                            { label: 'Total Liabilities', calculate: (year) =>
                                    (parseFloat(balanceSheetTrend[year]?.currentLiabilities) || 0) +
                                    (parseFloat(balanceSheetTrend[year]?.otherNonCurrentLiabilities) || 0) +
                                    (parseFloat(balanceSheetTrend[year]?.realEstateLiabilities) || 0)
                            },
                            { label: 'Net Worth', calculate: calculateNetWorth },
                            { label: 'Change In Net Worth', calculate: calculateChangeInNetWorth },
                            { label: 'RE Appreciation', field: 'reAppreciation' },
                            { label: 'Earned Gain', calculate: (year, prevYear) => {
                                    const changeInNetWorth = calculateChangeInNetWorth(year, prevYear);
                                    const reAppreciation = parseFloat(balanceSheetTrend[year]?.reAppreciation) || 0;
                                    return changeInNetWorth === 'N/A' ? 'N/A' : changeInNetWorth - reAppreciation;
                                }},
                            { label: 'Current Ratio', calculate: (year) => {
                                    const currentAssets = parseFloat(balanceSheetTrend[year]?.currentAssets) || 0;
                                    const currentLiabilities = parseFloat(balanceSheetTrend[year]?.currentLiabilities) || 0;
                                    return currentLiabilities !== 0 ? currentAssets / currentLiabilities : 'N/A';
                                }},
                            { label: 'Debt/Asset Ratio', calculate: (year) => {
                                    const totalAssets = (parseFloat(balanceSheetTrend[year]?.currentAssets) || 0) +
                                        (parseFloat(balanceSheetTrend[year]?.otherNonCurrentAssets) || 0) +
                                        (parseFloat(balanceSheetTrend[year]?.realEstate) || 0);
                                    const totalLiabilities = (parseFloat(balanceSheetTrend[year]?.currentLiabilities) || 0) +
                                        (parseFloat(balanceSheetTrend[year]?.otherNonCurrentLiabilities) || 0) +
                                        (parseFloat(balanceSheetTrend[year]?.realEstateLiabilities) || 0);
                                    return totalAssets !== 0 ? totalLiabilities / totalAssets : 'N/A';
                                }},
                            { label: 'Contingent Liabilities', field: 'contingentLiabilities' },
                        ].map((row, index) => (
                            <TableRow key={index}>
                                <TableCell>{row.label}</TableCell>
                                {years.map((year, yearIndex) => (
                                    <TableCell key={year} align="right">
                                        {row.field ?
                                            formatCurrency(balanceSheetTrend[year]?.[row.field]) :
                                            row.calculate ?
                                                (row.label === 'Current Ratio' || row.label === 'Debt/Asset Ratio' ?
                                                    formatRatio(row.calculate(year, years[yearIndex - 1])) :
                                                    formatCurrency(row.calculate(year, years[yearIndex - 1]))) :
                                                'N/A'
                                        }
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
        );
    };

    const IncomeStatementTrendAnalysis = () => {
        const principalBorrower = borrowers.find(b => b.is_principal) || {};
        const incomeAndExpenses = principalBorrower.incomeAndExpenses || {};
        const proformaData = loanData?.incomeAndExpensesProforma || {};

        const mostRecentYear = incomeAndExpenses.mostRecentTaxYear || new Date().getFullYear();
        const years = Array.from({ length: incomeAndExpenses.years || 4 }, (_, i) => (mostRecentYear - i).toString());
        years.reverse(); // Reverse to show oldest to newest
        years.push('Average', 'Pro-Forma');

        const formatCurrency = (value) => {
            return value ? `$${parseFloat(value).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}` : 'N/A';
        };

        const calculateAverage = (field) => {
            const sum = incomeAndExpenses.data?.reduce((acc, year) => acc + (parseFloat(year[field]) || 0), 0) || 0;
            return sum / (incomeAndExpenses.years || 1);
        };

        const calculateNetFarmIncome = (index) => {
            const data = incomeAndExpenses.data?.[index] || {};
            return (parseFloat(data.totalFarmIncome) || 0) - (parseFloat(data.totalFarmExpenses) || 0);
        };

        const calculateCashFlow = (index) => {
            const data = incomeAndExpenses.data?.[index] || {};
            return (
                calculateNetFarmIncome(index) +
                (parseFloat(data.netNonFarmIncome) || 0) +
                (parseFloat(data.depreciation) || 0) +
                (parseFloat(data.totalInterest) || 0) +
                (parseFloat(data.capitalLease) || 0) +
                (parseFloat(data.otherPlusAdjustment) || 0) -
                (parseFloat(data.incomeTax) || 0) -
                (parseFloat(data.familyLiving) || 0) -
                (parseFloat(data.nonRecurring) || 0) -
                (parseFloat(data.otherMinusAdjustment) || 0)
            );
        };

        const calculateTDC = (index) => {
            const cashFlow = calculateCashFlow(index);
            const data = incomeAndExpenses.data?.[index] || {};
            const debtService = (parseFloat(data.annualPI) || 0) + (parseFloat(data.annualCapLease) || 0);
            return debtService !== 0 ? cashFlow / debtService : 'N/A';
        };

        const rows = [
            { label: 'Total Farm Income', field: 'totalFarmIncome' },
            { label: 'Total Farm Expenses', field: 'totalFarmExpenses' },
            { label: 'Net Farm Income', calculate: calculateNetFarmIncome },
            { label: 'Net Non-Farm Income', field: 'netNonFarmIncome' },
            { label: 'Depreciation', field: 'depreciation' },
            { label: 'Total Interest', field: 'totalInterest' },
            { label: 'Ann. Cap. Lease', field: 'capitalLease' },
            { label: 'Other Plus Adjustment', field: 'otherPlusAdjustment' },
            { label: 'Income & FICA Tax', field: 'incomeTax' },
            { label: 'Family Living', field: 'familyLiving' },
            { label: 'Non-Recurring', field: 'nonRecurring' },
            { label: 'Other Minus Adjustment', field: 'otherMinusAdjustment' },
            { label: 'Cash Flow (CFA)', calculate: calculateCashFlow },
            { label: 'Annual P&I', field: 'annualPI' },
            { label: 'Ann. Cap. Lease', field: 'annualCapLease' },
            { label: 'Less P&I new land purchase', field: 'lessPINewLandPurchase' },
            { label: 'CFR', calculate: (index) => {
                    const data = incomeAndExpenses.data?.[index] || {};
                    return (parseFloat(data.annualPI) || 0) + (parseFloat(data.annualCapLease) || 0) - (parseFloat(data.lessPINewLandPurchase) || 0);
                }},
            { label: 'Excess cash', calculate: (index) => calculateCashFlow(index) - calculateCashFlow(index) },
            { label: 'TDC', calculate: calculateTDC },
            { label: 'TDC Shock 3%', calculate: (index) => {
                    const tdc = calculateTDC(index);
                    return tdc !== 'N/A' ? tdc * 0.97 : 'N/A';
                }},
            { label: 'TDC Shock 5%', calculate: (index) => {
                    const tdc = calculateTDC(index);
                    return tdc !== 'N/A' ? tdc * 0.95 : 'N/A';
                }},
        ];

        return (
            <Box mb={3}>
                <Typography variant="h6">Income Statement Trend Analysis</Typography>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Year</TableCell>
                            {years.map(year => (
                                <TableCell key={year} align="right">{year}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, rowIndex) => (
                            <TableRow key={rowIndex}>
                                <TableCell>{row.label}</TableCell>
                                {years.map((year, yearIndex) => (
                                    <TableCell key={year} align="right">
                                        {year === 'Average' ?
                                            (row.field ? formatCurrency(calculateAverage(row.field)) :
                                                row.calculate ? formatCurrency(row.calculate(yearIndex)) : 'N/A') :
                                            year === 'Pro-Forma' ?
                                                formatCurrency(proformaData[row.field]) :
                                                (row.field ?
                                                    formatCurrency(incomeAndExpenses.data?.[yearIndex]?.[row.field]) :
                                                    row.calculate ? formatCurrency(row.calculate(yearIndex)) : 'N/A')}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
        );
    };

    const QuestionnaireSection = () => {
        const { loanData } = loanDetailStore;
        const questionnaire = loanData?.application?.questionnaire || {};

        const questions = [
            { key: 'assetsSecurityForDebts', label: 'Are any assets pledged as security for debts of others?' },
            { key: 'hadBankruptcy', label: 'Have you had a bankruptcy? (past 7 years)' },
            { key: 'haveJudgments', label: 'Do you have any judgments against you?' },
            { key: 'partyToLawsuit', label: 'Are you a party to any lawsuit?' },
            { key: 'haveContingentLiabilities', label: 'Do you have any contingent liabilities?' },
            { key: 'endorsedGuaranteedNotes', label: 'Have you endorsed or guaranteed notes for others?' },
            { key: 'obligatedAlimonySupport', label: 'Are you obligated to pay alimony or support?' },
            { key: 'propertyForeclosed', label: 'Property foreclosed or deed in lieu? (past 7 years)' }
        ];

        return (
            <Box mb={3}>
                <Typography variant="h6" gutterBottom>These questions apply to all applicants. If yes to any, please explain.</Typography>
                {questions.map(({ key, label }) => (
                    <Typography key={key}>
                        {label}: {questionnaire[key] ? 'Yes' : 'No'}
                    </Typography>
                ))}
            </Box>
        );
    };

    return (
        <Box>
            <Button onClick={handlePrint} variant="contained" color="primary" sx={{ my: 2 }}>
                Generate PDF
            </Button>
            <Paper elevation={3} sx={{ p: 3, m: 2 }} ref={componentRef}>
                <Typography variant="h4" gutterBottom>Loan Summary Report</Typography>
                <Typography variant="subtitle1" gutterBottom>Print Date: {new Date().toLocaleDateString()}</Typography>
                <SellerInformation />
                <Divider sx={{ my: 2 }} />
                <BorrowerInformation />
                <Divider sx={{ my: 2 }} />
                <QuestionnaireSection />
                <LoanRequested />
                <UseOfFunds />
                <RealEstateSecurity />
                <IndividualBorrowerIncomeStatements />
                <Divider sx={{ my: 2 }} />
                <IndividualBorrowerBalanceSheets />
                <Divider sx={{ my: 2 }} />
                <IndividualBorrowerBSASchedules borrowers={borrowers} />
                <Divider sx={{ my: 2 }} />
                <SubsidiaryAnalysisReport loanData={loanData} borrowers={borrowers} />
                <Divider sx={{ my: 2 }} />
                <ConsolidatedBalanceSheet loanData={loanData} />
                <Divider sx={{ my: 2 }} />
                <ConsolidatedBSAReport loanData={loanData} borrowers={borrowers} />
                <Divider sx={{ my: 2 }} />
                <ConsolidatedIncomeExpenseProforma />
                <Divider sx={{ my: 2 }} />
                <ConsolidatedBalanceSheetProforma />
                <Divider sx={{ my: 2 }} />
                <ConsolidatedBSAProforma/>
                <BalanceSheetTrendAnalysis />
                <IncomeStatementTrendAnalysis />
                <ConsolidatedBSAProformaSchedules />
            </Paper>
        </Box>
    );
});

export default LoanSummaryReport;