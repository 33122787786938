import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    MenuItem,
    Radio,
    RadioGroup,
    TextField,
    Typography,
    Autocomplete
} from '@mui/material';
import {DatePicker} from '@mui/x-date-pickers';
import loanDetailStore from '../store';
import {US_STATES} from '../../../constants';
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFnsV3";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";


const formatTaxId = (borrowerType, inputValue, currentTaxId) => {
    const isIndividualType = borrowerType === 'Individual' || borrowerType === 'Individual + Spouse';
    if (!inputValue) return ''; // Return empty string if inputValue is undefined or null

    const digitsOnly = inputValue.replace(/\D/g, '');
    const maxLength = isIndividualType ? 9 : 9; // Adjust if needed
    const truncatedDigits = digitsOnly.slice(0, maxLength);

    if (isIndividualType) {
        if (currentTaxId && currentTaxId.startsWith('XXX-XX-') && inputValue.length > 11) {
            return inputValue.slice(-4);
        }
        const formattedValue = truncatedDigits.replace(/^(\d{3})(\d{2})(\d{4})$/, '$1-$2-$3');
        if (formattedValue.length === 11) {
            return `XXX-XX-${formattedValue.slice(-4)}`;
        }
        return formattedValue;
    } else {
        return truncatedDigits.replace(/^(\d{2})(\d{7})$/, '$1-$2');
    }
};


const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

const formatPhoneNumber = (inputValue) => {
    const digitsOnly = inputValue.replace(/\D/g, '');
    const truncatedDigits = digitsOnly.slice(0, 10);

    if (truncatedDigits.length <= 3) {
        return truncatedDigits;
    } else if (truncatedDigits.length <= 6) {
        return `(${truncatedDigits.slice(0, 3)}) ${truncatedDigits.slice(3)}`;
    } else {
        return `(${truncatedDigits.slice(0, 3)}) ${truncatedDigits.slice(3, 6)}-${truncatedDigits.slice(6)}`;
    }
};

const CommonFields = observer(({borrower, handleBorrowerChange, maskedTaxId, handleTaxIdChange}) => (<>
    <Grid item xs={12}>
        <TextField
            name="address"
            label="Address"
            value={borrower.address || ''}
            onChange={(e) => handleBorrowerChange(borrower.id, 'address', e.target.value)}
            fullWidth
            margin="normal"
        />
    </Grid>
    <Grid item xs={12} sm={6}>
        <TextField
            name="city"
            label="City"
            value={borrower.city || ''}
            onChange={(e) => handleBorrowerChange(borrower.id, 'city', e.target.value)}
            fullWidth
            margin="normal"
        />
    </Grid>
    <Grid item xs={12} sm={3}>
        <Autocomplete
            options={US_STATES}
            getOptionLabel={(option) => option}
            value={borrower.state || ''}
            onChange={(event, newValue) => handleBorrowerChange(borrower.id, 'state', newValue)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="State"
                    fullWidth
                    margin="normal"
                />
            )}
        />
    </Grid>
    <Grid item xs={12} sm={3}>
        <TextField
            name="zip"
            label="Zip Code"
            value={borrower.zip || ''}
            onChange={(e) => handleBorrowerChange(borrower.id, 'zip', e.target.value)}
            fullWidth
            margin="normal"
        />
    </Grid>
    <Grid item xs={12} sm={6}>
        <TextField
            name="phone"
            label="Phone"
            value={borrower.phone || ''}
            onChange={(e) => {
                const inputValue = e.target.value;
                const formattedValue = formatPhoneNumber(inputValue);
                handleBorrowerChange(borrower.id, 'phone', formattedValue);
            }}
            fullWidth
            margin="normal"
            inputProps={{
                maxLength: 14,
            }}
        />
    </Grid>
    <Grid item xs={12} sm={6}>
        <TextField
            name="email"
            label="Email"
            value={borrower.email || ''}
            onChange={(e) => {
                const inputValue = e.target.value;
                handleBorrowerChange(borrower.id, 'email', inputValue);
            }}
            fullWidth
            margin="normal"
            error={!isValidEmail(borrower.email)}
            helperText={!isValidEmail(borrower.email) && 'Please enter a valid email address'}
        />
    </Grid>
    <Grid item xs={12} sm={4}>
        <TextField
            name="taxId"
            label={(borrower.type === 'Individual' || borrower.type === 'Individual + Spouse')  ? 'SSN' : 'EIN'}
            value={maskedTaxId}
            onChange={(e) => handleTaxIdChange(borrower.id, e.target.value)}
            fullWidth
            margin="normal"
            inputProps={{
                maxLength: (borrower.type === 'Individual' || borrower.type === 'Individual + Spouse') ? 11 : 10,
            }}
        />
    </Grid>
    {(borrower.type === 'Individual' || borrower.type === 'Individual + Spouse') && (
        <>
            <Grid item xs={12} sm={4}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label="Date of Birth"
                        value={borrower?.dob?.toDate ? borrower?.dob?.toDate() : null}
                        onChange={(date) => handleBorrowerChange(borrower.id, 'dob', date)}
                        format="MM/dd/yyyy"
                        slotProps={{textField: {fullWidth: true, margin: "normal"}}}
                    />
                </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextField
                    name="cbrScore"
                    label="CBR Score"
                    value={borrower.cbrScore || ''}
                    onChange={(e) => handleBorrowerChange(borrower.id, 'cbrScore', e.target.value)}
                    fullWidth
                    margin="normal"
                />
            </Grid>
        </>
    )}

    <Grid item xs={12}>
        <FormControlLabel
            control={<Checkbox
                name="nonUSCitizen"
                checked={borrower.nonUSCitizen || false}
                onChange={(e) => handleBorrowerChange(borrower.id, 'nonUSCitizen', e.target.checked)}
            />}
            label="Check here if you are NOT a US Citizen or Permanent Alien"
        />
    </Grid>
</>));

const BorrowerDetails = observer(() => {
    const {loanData, updateLoanData, handleBorrowerChange, addNewBorrower, removeBorrower, borrowers} = loanDetailStore;

    const [maskedTaxIds, setMaskedTaxIds] = useState({});
// In the useEffect hook:
    useEffect(() => {
        borrowers.forEach(async (borrower) => {
            // Format and mask the tax ID
            const formattedValue = formatTaxId(borrower.type, borrower.taxId || '', maskedTaxIds[borrower.id]);

            // Update the state
            setMaskedTaxIds(prevState => ({
                ...prevState, [borrower.id]: formattedValue,
            }));
        });
        // eslint-disable-next-line
    }, [borrowers]);


    const handleTaxIdChange = (borrowerId, inputValue) => {
        const borrower = borrowers.find(b => b.id === borrowerId);
        const formattedValue = formatTaxId(borrower.type, inputValue, maskedTaxIds[borrowerId]);
        setMaskedTaxIds(prevState => ({
            ...prevState,
            [borrowerId]: formattedValue,
        }));
        handleBorrowerChange(borrowerId, 'taxId', inputValue);
    };

    const handleRadioChange = (event) => {
        const {name, value} = event.target;
        updateLoanData({
            ...loanData, application: {
                ...loanData.application, [name]: value === 'yes',
            },
        });
    };

    return (<Box>
        <Typography variant="h6" mb={1}>Borrower Details</Typography>
        <Box display="flex" alignItems="center" mb={2}>
            <RadioGroup
                name="otherLoansWithOriginator"
                value={loanData?.application?.otherLoansWithOriginator ? 'yes' : 'no'}
                onChange={handleRadioChange}
                row
            >
                <FormControlLabel value="yes" control={<Radio/>} label="Yes"/>
                <FormControlLabel value="no" control={<Radio/>} label="No"/>
            </RadioGroup>
            <Typography variant="body1">Do applicants have other loans with originator?</Typography>
        </Box>
        <Box display="flex" alignItems="center" mb={2}>
            <RadioGroup
                name="guaranteesOnLoan"
                value={loanData?.application?.guaranteesOnLoan ? 'yes' : 'no'}
                onChange={handleRadioChange}
                row
            >
                <FormControlLabel value="yes" control={<Radio/>} label="Yes"/>
                <FormControlLabel value="no" control={<Radio/>} label="No"/>
            </RadioGroup>
            <Typography variant="body1">Are there guarantees on this loan?</Typography>
        </Box>
        <Box display="flex" alignItems="center" mb={2}>
            <RadioGroup
                name="coApplicantsOnLoan"
                value={loanData?.application?.coApplicantsOnLoan ? 'yes' : 'no'}
                onChange={handleRadioChange}
                row
            >
                <FormControlLabel value="yes" control={<Radio/>} label="Yes"/>
                <FormControlLabel value="no" control={<Radio/>} label="No"/>
            </RadioGroup>
            <Typography variant="body1">Are there co-applicants for this loan?</Typography>
        </Box>
        {borrowers.slice()
            .sort((a, b) => {
                if (!a.createdAt) return -1;
                if (!b.createdAt) return -1;
                return new Date(a.createdAt) - new Date(b.createdAt); // Sort by createdAt
            }).map((borrower, index) => (<Box key={borrower.id} mb={2}>
                <Typography variant="subtitle1" mb={1}>Borrower {index + 1}</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            name="type"
                            label="Type"
                            value={borrower.type || ''}
                            onChange={(e) => handleBorrowerChange(borrower.id, 'type', e.target.value)}
                            fullWidth
                            margin="normal"
                            select
                        >
                            <MenuItem disabled value="">
                                Select Borrower Type
                            </MenuItem>
                            <MenuItem value="Individual">Individual</MenuItem>
                            <MenuItem value="Individual + Spouse">Individual + Spouse</MenuItem>
                            <MenuItem value="Entity">Entity</MenuItem>
                            <MenuItem value="Trust">Trust</MenuItem>
                            {/* Add other borrower types if needed */}
                        </TextField>

                    </Grid>
                    {(borrower.type === 'Individual' || borrower.type === 'Individual + Spouse') && (<>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                name="firstName"
                                label="First Name"
                                value={borrower.firstName || ''}
                                onChange={(e) => handleBorrowerChange(borrower.id, 'firstName', e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                name="middleName"
                                label="Middle Name"
                                value={borrower.middleName || ''}
                                onChange={(e) => handleBorrowerChange(borrower.id, 'middleName', e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                name="lastName"
                                label="Last Name"
                                value={borrower.lastName || ''}
                                onChange={(e) => handleBorrowerChange(borrower.id, 'lastName', e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                select
                                name="suffix"
                                label="Suffix"
                                value={borrower.suffix || ''}
                                onChange={(e) => handleBorrowerChange(borrower.id, 'suffix', e.target.value)}
                                fullWidth
                                margin="normal"
                            >
                                <MenuItem value="">None</MenuItem>
                                <MenuItem value="Jr.">Jr.</MenuItem>
                                <MenuItem value="Sr.">Sr.</MenuItem>
                                <MenuItem value="II">II</MenuItem>
                                <MenuItem value="III">III</MenuItem>
                                <MenuItem value="IV">IV</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                name="nameAffidavit"
                                label="Name Affidavit"
                                value={borrower.nameAffidavit || ''}
                                onChange={(e) => handleBorrowerChange(borrower.id, 'nameAffidavit', e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                        </Grid>
                        {borrower.type === 'Individual + Spouse' && (
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    name="spouseName"
                                    label="Spouse Name"
                                    value={borrower.spouseName || ''}
                                    onChange={(e) => handleBorrowerChange(borrower.id, 'spouseName', e.target.value)}
                                    fullWidth
                                    margin="normal"
                                />
                            </Grid>
                        )}
                        <CommonFields
                            borrower={borrower}
                            handleBorrowerChange={handleBorrowerChange}
                            maskedTaxId={maskedTaxIds[borrower.id] || ''}
                            handleTaxIdChange={handleTaxIdChange}
                        />
                    </>)}
                    {borrower.type === 'Entity' && (<>
                        <Grid item xs={12}>
                            <TextField
                                name="entityName"
                                label="Entity Name"
                                value={borrower.entityName || ''}
                                onChange={(e) => handleBorrowerChange(borrower.id, 'entityName', e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                select
                                name="organizationState"
                                label="Organization State"
                                value={borrower.organizationState || ''}
                                onChange={(e) => handleBorrowerChange(borrower.id, 'organizationState', e.target.value)}
                                fullWidth
                                margin="normal"
                            >
                                {US_STATES.map((state) => (<MenuItem key={state} value={state}>
                                    {state}
                                </MenuItem>))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="organizationType"
                                label="Organization Type"
                                value={borrower.organizationType || ''}
                                onChange={(e) => handleBorrowerChange(borrower.id, 'organizationType', e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="organizationId"
                                label="Organization ID#"
                                value={borrower.organizationId || ''}
                                onChange={(e) => handleBorrowerChange(borrower.id, 'organizationId', e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                        </Grid>
                        <CommonFields
                            borrower={borrower}
                            handleBorrowerChange={handleBorrowerChange}
                            maskedTaxId={maskedTaxIds[borrower.id] || ''}
                            handleTaxIdChange={handleTaxIdChange}
                        />
                    </>)}
                    {borrower.type === 'Trust' && (<>
                        <Grid item xs={12}>
                            <TextField
                                name="trustName"
                                label="Trust Name"
                                value={borrower.trustName || ''}
                                onChange={(e) => handleBorrowerChange(borrower.id, 'trustName', e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="beneficiaryName"
                                label="Beneficiary Name"
                                value={borrower.beneficiaryName || ''}
                                onChange={(e) => handleBorrowerChange(borrower.id, 'beneficiaryName', e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="trustDate"
                                label="Trust Date"
                                value={borrower.trustDate || ''}
                                onChange={(e) => handleBorrowerChange(borrower.id, 'trustDate', e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                select
                                name="trustState"
                                label="Trust State"
                                value={borrower.trustState || ''}
                                onChange={(e) => handleBorrowerChange(borrower.id, 'trustState', e.target.value)}
                                fullWidth
                                margin="normal"
                            >
                                {US_STATES.map((state) => (<MenuItem key={state} value={state}>
                                    {state}
                                </MenuItem>))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="trustees"
                                label="Trustees"
                                value={borrower.trustees || ''}
                                onChange={(e) => handleBorrowerChange(borrower.id, 'trustees', e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="settlors"
                                label="Settlors"
                                value={borrower.settlors || ''}
                                onChange={(e) => handleBorrowerChange(borrower.id, 'settlors', e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                        </Grid>
                        <CommonFields
                            borrower={borrower}
                            handleBorrowerChange={handleBorrowerChange}
                            maskedTaxId={maskedTaxIds[borrower.id] || ''}
                            handleTaxIdChange={handleTaxIdChange}
                        />
                    </>)}
                    <Grid item xs={12}>
                        <Button variant="contained" color="secondary" onClick={() => removeBorrower(borrower.id)}>
                            Remove Borrower
                        </Button>
                    </Grid>
                </Grid>
            </Box>))}
        <Button variant="contained" onClick={addNewBorrower} style={{marginTop: '16px'}}>
            Add Borrower
        </Button>
    </Box>);
});

export default BorrowerDetails;