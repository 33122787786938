import React, {useCallback, useMemo} from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Typography, TextField, Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import CurrencyTextField from '@lupus-ai/mui-currency-textfield/dist/CurrencyTextField';
import loanDetailStore from '../store';

const ScheduleC = observer(({ borrowerData, readOnly, proforma }) => {
  const { updateBSAData, addBSAItem, deleteBSAItem, updateBSAProformaData, addBSAProformaItem, deleteBSAProformaItem, selectedBorrowerId } = loanDetailStore;

  const selectedBorrowerLocalId = useMemo(() => {
    let result;
    if (readOnly || proforma) {
      result = borrowerData.find(borrower => borrower.is_principal === true)["id"];
    } else {
      result = selectedBorrowerId;
    }
    if (!result) {
      console.warn('No borrower found!');
    }
    return result;
  }, [selectedBorrowerId, readOnly, proforma, borrowerData]);

  const selectedBorrower = useMemo(() => {
    return borrowerData.find(borrower => borrower.id === selectedBorrowerLocalId);
  }, [selectedBorrowerLocalId, borrowerData]);

  const handleUpdateItem = useCallback((borrowerId, index, field, value) => {
    if (proforma) {
      updateBSAProformaData(borrowerId, 'c', index, field, value);
    } else {
      updateBSAData(borrowerId, 'c', index, field, value);
    }
  }, [updateBSAData, updateBSAProformaData, proforma]);

  const handleAddItem = useCallback(() => {
    if (proforma) {
      addBSAProformaItem('c');
    } else {
      addBSAItem(selectedBorrowerLocalId, 'c');
    }
  }, [addBSAItem, addBSAProformaItem, proforma, selectedBorrowerLocalId]);

  const handleDeleteItem = useCallback((borrowerId, index) => {
    if (proforma) {
      deleteBSAProformaItem(borrowerId, 'c', index);
    } else {
      deleteBSAItem(borrowerId, 'c', index);
    }
  }, [deleteBSAItem, deleteBSAProformaItem, proforma]);

  const getFieldValue = useCallback((item, field) => {
    return proforma ? (item.proforma?.[field] ?? item[field] ?? '') : (item[field] ?? '');
  }, [proforma]);

  const renderScheduleItems = () => {
    if (readOnly) {
      return borrowerData.flatMap(borrower => borrower.bsas?.c || []);
    }

    if (proforma) {
      return borrowerData.flatMap(borrower =>
          (borrower.bsas?.c || []).map(item => ({
            ...item,
            ...(item.proforma || {}),
            borrowerId: borrower.id,
          }))
      );
    }

    return (selectedBorrower.bsas?.c || []).map((item, index) => ({ ...item, index }));
  };

  const scheduleItems = renderScheduleItems();

  const calculateTotals = () => {
    return scheduleItems.reduce((totals, item) => {
      totals.balance += parseFloat(getFieldValue(item, 'balance')) || 0;
      totals.accruedInterest += parseFloat(getFieldValue(item, 'accruedInterest')) || 0;
      return totals;
    }, { balance: 0, accruedInterest: 0 });
  };

  const totals = calculateTotals();

  return (
      <>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Schedule C - Current Debt - Operating Lines, Market Livestock, Due in 12 Months</Typography>
        {scheduleItems.map((item, index) => (
            <Box key={index} mb={2}>
              <Box display="flex" gap={2} mb={1}>
                <TextField
                    label="Holder"
                    value={getFieldValue(item, 'holder')}
                    onChange={(e) => handleUpdateItem(item.borrowerId || selectedBorrowerLocalId, index, 'holder', e.target.value)}
                    InputProps={{ readOnly: readOnly }}
                />
                <TextField
                    label="Security"
                    value={getFieldValue(item, 'security')}
                    onChange={(e) => handleUpdateItem(item.borrowerId || selectedBorrowerLocalId, index, 'security', e.target.value)}
                    InputProps={{ readOnly: readOnly }}
                />
                <CurrencyTextField
                    variant="outlined"
                    label="Balance"
                    value={getFieldValue(item, 'balance')}
                    onChange={(e) => handleUpdateItem(item.borrowerId || selectedBorrowerLocalId, index, 'balance', parseFloat(e.target.value.replace(/,/g, "")) || '')}
                    InputProps={{ readOnly: readOnly }}
                />
                <TextField
                    label="Rate"
                    type="number"
                    value={getFieldValue(item, 'rate')}
                    onChange={(e) => handleUpdateItem(item.borrowerId || selectedBorrowerLocalId, index, 'rate', parseFloat(e.target.value) || '')}
                    InputProps={{ readOnly: readOnly }}
                />
                <CurrencyTextField
                    variant="outlined"
                    label="Accrued Interest"
                    value={(parseFloat(getFieldValue(item, 'balance')) || 0) * (parseFloat(getFieldValue(item, 'rate')/100) || 0)}
                    InputProps={{ readOnly: true }}
                />

                {(proforma || !readOnly) && (
                  <IconButton onClick={() => handleDeleteItem(item.borrowerId || selectedBorrowerLocalId, index)}>
                    <DeleteIcon />
                  </IconButton>
              )}
              </Box>
            </Box>
        ))}
        {(proforma || !readOnly) && (
            <Box display="flex" justifyContent="flex-start" mb={2}>
              <Button variant="contained" onClick={handleAddItem}>
                Add Item
              </Button>
            </Box>
        )}
        {(readOnly || proforma) && (
            <Box display="flex" gap={2} my={3} justifyContent="flex-end">
              <CurrencyTextField
                  variant="outlined"
                  label="Total Balance"
                  value={totals.balance}
                  InputProps={{ readOnly: true }}
                  InputLabelProps={{ style: { fontWeight: 'bold' } }}
                  inputProps={{ style: { fontWeight: 'bold' } }}
              />
              <CurrencyTextField
                  variant="outlined"
                  label="Total Accrued Interest"
                  value={totals.accruedInterest}
                  InputProps={{ readOnly: true }}
                  InputLabelProps={{ style: { fontWeight: 'bold' } }}
                  inputProps={{ style: { fontWeight: 'bold' } }}
              />
            </Box>
        )}
      </>
  );
});

export default ScheduleC;