import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Typography, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import loanDetailStore from '../store';

const QuestionnaireSection = observer(() => {
  const { loanData, updateLoanData } = loanDetailStore;

  const handleRadioChange = (event) => {
    const { name, value } = event.target;
    updateLoanData({
      application: {
        ...loanData.application,
        questionnaire: { ...loanData.application.questionnaire, [name]: value },
        explanations: value === 'No' ? { ...loanData.application.explanations, [name]: '' } : loanData.application.explanations
      }
    });
  };

  const handleExplanationChange = (event) => {
    const { name, value } = event.target;
    updateLoanData({
      application: {
        ...loanData.application,
        explanations: { ...loanData.application.explanations, [name]: value }
      }
    });
  };

  return (
    <Box>
      <Typography variant="h6" mb={1}>These questions apply to all applicants.</Typography>
      {[
        { name: 'assetsSecurityForDebts', text: 'Are any assets pledged as security for debts of others?' },
        { name: 'hadBankruptcy', text: 'Have you had a bankruptcy? (past 7 years)' },
        { name: 'haveJudgments', text: 'Do you have any judgments against you?' },
        { name: 'partyToLawsuit', text: 'Are you a party to any lawsuit?' },
        { name: 'haveContingentLiabilities', text: 'Do you have any contingent liabilities?' },
        { name: 'endorsedGuaranteedNotes', text: 'Have you endorsed or guaranteed notes for others?' },
        { name: 'obligatedAlimonySupport', text: 'Are you obligated to pay alimony or support?' },
        { name: 'propertyForeclosed', text: 'Property foreclosed or deed in lieu? (past 7 years)' },
      ].map((question) => (
        <Box key={question.name} mb={2}>
          <Typography>{question.text}</Typography>
          <RadioGroup
            name={question.name}
            value={loanData?.application?.questionnaire?.[question.name] || 'No'}
            onChange={handleRadioChange}
            row
          >
            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="No" control={<Radio />} label="No" />
          </RadioGroup>
          {loanData?.application?.questionnaire?.[question.name] === 'Yes' && (
            <TextField
              name={question.name}
              label="Please provide an explanation"
              value={loanData?.application?.explanations?.[question.name] || ''}
              onChange={handleExplanationChange}
              fullWidth
              margin="normal"
              multiline
              rows={4}
            />
          )}
        </Box>
      ))}
    </Box>
  );
});

export default QuestionnaireSection;