import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Typography, TextField, FormControlLabel, MenuItem, Grid, Button } from '@mui/material';
import { Radio, RadioGroup, FormControl } from '@mui/material';

import loanDetailStore from '../store';
import { US_STATES } from '../../../constants';

const Security = observer(() => {
    const { loanData, updateLoanData } = loanDetailStore;

    // Ensure securities is an array
    const securities = loanData?.application?.securities || [];

    // Function to handle input changes
    const handleInputChange = (timestamp) => (event) => {
        const { name, value } = event.target;
        const updatedSecurities = securities.map((security) => {
            if (security.timestamp === timestamp) {
                return { ...security, [name]: value };
            }
            return security;
        });
        updateLoanData({ application: { ...loanData.application, securities: updatedSecurities } });
    };

    // Function to handle radio changes
    const handleRadioChange = (timestamp) => (event) => {
        const { name, value } = event.target;
        const updatedSecurities = securities.map((security) => {
            if (security.timestamp === timestamp) {
                return { ...security, [name]: value === 'yes' };
            }
            return security;
        });
        updateLoanData({ application: { ...loanData.application, securities: updatedSecurities } });
    };

    // Function to add a new security
    const addSecurity = () => {
        const newSecurity = {
            timestamp: Date.now(),
            // Initialize other fields if necessary
        };
        const updatedSecurities = [...securities, newSecurity]; // Add the new security at the end
        updateLoanData({ application: { ...loanData.application, securities: updatedSecurities } });
    };

    // Function to remove a security
    const removeSecurity = (timestamp) => {
        const updatedSecurities = securities.filter((security) => security.timestamp !== timestamp);
        updateLoanData({ application: { ...loanData.application, securities: updatedSecurities } });
    };

    // Sort the securities by timestamp
    const sortedSecurities = [...securities].sort((a, b) => a.timestamp - b.timestamp);

    return (
        <Box>
            <Typography variant="h6" mb={1}>Security</Typography>
            {sortedSecurities.map((security, index) => (
                <Box key={security.timestamp} mb={4}>
                    <Typography variant="subtitle1">Security #{index + 1}</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                label="Acres"
                                name="acres"
                                value={security.acres || ''}
                                onChange={handleInputChange(security.timestamp)}
                                fullWidth
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                label="County"
                                name="county"
                                value={security.county || ''}
                                onChange={handleInputChange(security.timestamp)}
                                fullWidth
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                select
                                label="State"
                                name="state"
                                value={security.state || ''}
                                onChange={handleInputChange(security.timestamp)}
                                fullWidth
                                margin="normal"
                            >
                                {US_STATES.map((state) => (
                                    <MenuItem key={state} value={state}>
                                        {state}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="The title is/will be vested in:"
                                name="titleVestedIn"
                                value={security.titleVestedIn || ''}
                                onChange={handleInputChange(security.timestamp)}
                                fullWidth
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl component="fieldset">
                                <Typography component="legend">Is this property irrigated?</Typography>
                                <RadioGroup
                                    row
                                    name="isIrrigated"
                                    value={security.isIrrigated ? 'yes' : 'no'}
                                    onChange={handleRadioChange(security.timestamp)}
                                >
                                    <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                    <FormControlLabel value="no" control={<Radio />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" mb={1}>Legal Description</Typography>
                            <TextField
                                name="legalDescription"
                                value={security.legalDescription || ''}
                                onChange={handleInputChange(security.timestamp)}
                                fullWidth
                                margin="normal"
                                multiline
                                rows={4}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" mb={1}>
                                Additional Security (Include federal &amp; state grazing leases, irrigation equipment, water rights &amp; other equipment necessary to the operation of the security):
                            </Typography>
                            <TextField
                                name="additionalSecurity"
                                value={security.additionalSecurity || ''}
                                onChange={handleInputChange(security.timestamp)}
                                fullWidth
                                margin="normal"
                                multiline
                                rows={4}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" mb={1}>
                                Description of property and improvements (including leased equipment i.e. irrigation pivots):
                            </Typography>
                            <TextField
                                name="propertyDescription"
                                value={security.propertyDescription || ''}
                                onChange={handleInputChange(security.timestamp)}
                                fullWidth
                                margin="normal"
                                multiline
                                rows={4}
                            />
                        </Grid>
                    </Grid>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => removeSecurity(security.timestamp)}
                        style={{ marginTop: '16px' }}
                    >
                        Remove Security
                    </Button>
                </Box>
            ))}
            <Button variant="contained" color="primary" onClick={addSecurity}>
                Add Security
            </Button>
        </Box>
    );
});

export default Security;
