import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Typography, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

const ConsolidatedBSAReport = observer(({ loanData, borrowers }) => {
    const formatCurrency = (value) => {
        return value ? parseFloat(value).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) : 'N/A';
    };

    const consolidatedBSA = useMemo(() => {
        const schedules = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k'];
        const consolidated = {};

        schedules.forEach(schedule => {
            consolidated[schedule] = borrowers.reduce((acc, borrower) => {
                const borrowerSchedule = borrower.bsas?.[schedule] || [];
                return acc.concat(borrowerSchedule.map(item => ({ ...item, borrowerId: borrower.id })));
            }, []);
        });

        return consolidated;
    }, [borrowers]);

    const renderSchedule = (schedule, title, columns) => {
        const items = consolidatedBSA[schedule] || [];

        const calculateTotal = (field) => {
            return items.reduce((sum, item) => sum + (parseFloat(item[field]) || 0), 0);
        };

        return (
            <Box mb={3}>
                <Typography variant="h6">{title}</Typography>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell key={column.field}>{column.label}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map((item, index) => (
                            <TableRow key={index}>
                                {columns.map((column) => (
                                    <TableCell key={column.field}>
                                        {column.format ? column.format(item[column.field]) : item[column.field]}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell colSpan={columns.length - 1}><strong>Total:</strong></TableCell>
                            <TableCell>
                                <strong>${formatCurrency(calculateTotal(columns[columns.length - 1].field))}</strong>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Box>
        );
    };

    return (
        <Box>
            <Typography variant="h5" gutterBottom>Consolidated Balance Sheet Schedule Report</Typography>
            <Typography>Application No: {loanData?.id || 'N/A'}</Typography>

            {renderSchedule('a', 'Schedule A - Real Estate and Debt', [
                { field: 'newLoanCollateral', label: 'New Loan Collateral?' },
                { field: 'acres', label: 'Acres' },
                { field: 'year', label: 'Year' },
                { field: 'description', label: 'Description' },
                { field: 'purchasePrice', label: 'Purchase Price', format: (value) => `$${formatCurrency(value)}` },
                { field: 'marketValue', label: 'Market Value', format: (value) => `$${formatCurrency(value)}` },
                { field: 'payableTo', label: 'Payable To' },
                { field: 'balance', label: 'Balance', format: (value) => `$${formatCurrency(value)}` },
                { field: 'rate', label: 'Rate' },
                { field: 'annualPayment', label: 'Annual Payment', format: (value) => `$${formatCurrency(value)}` }
            ])}

            {renderSchedule('b', 'Schedule B - Chattel Debt (Machinery, Breeding Livestock, etc.) & Notes Payable', [
                { field: 'holder', label: 'Holder' },
                { field: 'security', label: 'Security' },
                { field: 'balance', label: 'Balance', format: (value) => `$${formatCurrency(value)}` },
                { field: 'rate', label: 'Rate' },
                { field: 'annualPayment', label: 'Annual Payment', format: (value) => `$${formatCurrency(value)}` }
            ])}

            {renderSchedule('c', 'Schedule C - Current Debt - Operating Lines, Market Livestock, Due in 12 Months', [
                { field: 'holder', label: 'Holder' },
                { field: 'security', label: 'Security' },
                { field: 'balance', label: 'Balance', format: (value) => `$${formatCurrency(value)}` },
                { field: 'date', label: 'Date' },
                { field: 'accruedInterest', label: 'Accrued Interest', format: (value) => `$${formatCurrency(value)}` }
            ])}

            {renderSchedule('d', 'Schedule D - Accounts Receivable', [
                { field: 'receivedFrom', label: 'Received From' },
                { field: 'amount', label: 'Amount', format: (value) => `$${formatCurrency(value)}` }
            ])}

            {renderSchedule('e', 'Schedule E - Notes Receivable', [
                { field: 'receivedFrom', label: 'Received From' },
                { field: 'amount', label: 'Amount', format: (value) => `$${formatCurrency(value)}` },
                { field: 'rate', label: 'Rate' },
                { field: 'interest', label: 'Interest', format: (value) => `$${formatCurrency(value)}` },
                { field: 'principal', label: 'Principal', format: (value) => `$${formatCurrency(value)}` },
                { field: 'value', label: 'Value', format: (value) => `$${formatCurrency(value)}` }
            ])}

            {renderSchedule('f', 'Schedule F - Growing Crops (at Cost)', [
                { field: 'crop', label: 'Crop' },
                { field: 'acres', label: 'Acres' },
                { field: 'costPerAcre', label: 'Cost Per Acre', format: (value) => `$${formatCurrency(value)}` },
                { field: 'principal', label: 'Principal', format: (value) => `$${formatCurrency(value)}` },
                { field: 'value', label: 'Value', format: (value) => `$${formatCurrency(value)}` }
            ])}

            {renderSchedule('g', 'Schedule G - Harvested Crops', [
                { field: 'crop', label: 'Crop' },
                { field: 'amount', label: 'Amount' },
                { field: 'units', label: 'Units' },
                { field: 'price', label: 'Price', format: (value) => `$${formatCurrency(value)}` },
                { field: 'value', label: 'Value', format: (value) => `$${formatCurrency(value)}` }
            ])}

            {renderSchedule('h', 'Schedule H - Breeding Livestock', [
                { field: 'type', label: 'Type' },
                { field: 'number', label: 'Number' },
                { field: 'price', label: 'Price', format: (value) => `$${formatCurrency(value)}` },
                { field: 'value', label: 'Value', format: (value) => `$${formatCurrency(value)}` }
            ])}

            {renderSchedule('i', 'Schedule I - Market Livestock', [
                { field: 'type', label: 'Type' },
                { field: 'number', label: 'Number' },
                { field: 'price', label: 'Price', format: (value) => `$${formatCurrency(value)}` },
                { field: 'value', label: 'Value', format: (value) => `$${formatCurrency(value)}` }
            ])}

            {renderSchedule('j', 'Schedule J - Leased Machinery, Equipment and Vehicles', [
                { field: 'leaser', label: 'Leaser' },
                { field: 'description', label: 'Description' },
                { field: 'purchaseOption', label: 'Purchase Option' },
                { field: 'numOfPayments', label: 'Number of Payments' },
                { field: 'annualLeasePayment', label: 'Annual Lease Payment', format: (value) => `$${formatCurrency(value)}` }
            ])}

            {renderSchedule('k', 'Schedule K - Owned Machinery, Equipment and Vehicles', [
                { field: 'year', label: 'Year' },
                { field: 'make', label: 'Make' },
                { field: 'description', label: 'Description' },
                { field: 'depreciatedValue', label: 'Depreciated Value', format: (value) => `$${formatCurrency(value)}` },
                { field: 'marketValue', label: 'Market Value', format: (value) => `$${formatCurrency(value)}` }
            ])}
        </Box>
    );
});

export default ConsolidatedBSAReport;