import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Typography, Table, TableBody, TableCell, TableHead, TableRow, Paper } from '@mui/material';
import loanDetailStore from '../store';

const ConsolidatedBSAProforma = observer(() => {
    const { loanData } = loanDetailStore;
    const proformaData = loanData?.bsaProforma || {};

    const formatCurrency = (value) => {
        return value ? `$${parseFloat(value).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}` : 'N/A';
    };

    const renderSchedule = (schedule, title) => (
        <Box mb={3}>
            <Typography variant="h6">{title}</Typography>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        {Object.keys(schedule[0] || {}).map((key) => (
                            <TableCell key={key}>{key}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {schedule.map((item, index) => (
                        <TableRow key={index}>
                            {Object.entries(item).map(([key, value]) => (
                                <TableCell key={key}>
                                    {key.toLowerCase().includes('value') || key.toLowerCase().includes('price') || key.toLowerCase().includes('balance')
                                        ? formatCurrency(value)
                                        : value}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Box>
    );

    return (
        <Paper elevation={3} sx={{ p: 3, m: 2 }}>
            <Typography variant="h5" gutterBottom>Consolidated Balance Sheet(a) Schedule Proforma Report</Typography>
            <Typography variant="subtitle1" gutterBottom>Application No: {loanData?.id || 'N/A'}</Typography>

            {renderSchedule(proformaData.scheduleA || [], "Schedule A - Real Estate and Debt")}
            {renderSchedule(proformaData.scheduleB || [], "Schedule B - Chattel Debt (Machinery, Breeding Livestock, etc.) & Notes Payable")}
            {renderSchedule(proformaData.scheduleC || [], "Schedule C - Current Debt - Operating Lines, Market Livestock, Due in 12 Months Payable")}
            {renderSchedule(proformaData.scheduleD || [], "Schedule D - Accounts Receivable")}
            {renderSchedule(proformaData.scheduleE || [], "Schedule E - Notes Receivable")}
            {renderSchedule(proformaData.scheduleF || [], "Schedule F - Growing Crops (at Cost)")}
            {renderSchedule(proformaData.scheduleG || [], "Schedule G - Harvested Crops")}
            {renderSchedule(proformaData.scheduleH || [], "Schedule H - Breeding Livestock")}
            {renderSchedule(proformaData.scheduleI || [], "Schedule I - Market Livestock")}
            {renderSchedule(proformaData.scheduleJ || [], "Schedule J - Leased Machinery, Equipment and Vehicles")}
            {renderSchedule(proformaData.scheduleK || [], "Schedule K - Owned Machinery, Equipment and Vehicles")}
        </Paper>
    );
});

export default ConsolidatedBSAProforma;