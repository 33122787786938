import React from 'react';
import {observer} from 'mobx-react-lite';
import {Box, Typography, TextField, MenuItem, Grid, Divider, FormControlLabel, Checkbox} from '@mui/material';
import loanDetailStore from '../store';
import CurrencyTextField from '@lupus-ai/mui-currency-textfield/dist/CurrencyTextField';

const IncomeAndExpensesBase = observer(({proforma}) => {
    const {
        borrowers,
        selectedBorrowerId,
        setSelectedBorrowerId,
        updateIncomeAndExpenses,
        updateIncomeAndExpensesProforma,
        loanData
    } = loanDetailStore;

    const selectedBorrower = proforma
        ? (borrowers.find(b => b.is_principal) || borrowers[0])
        : borrowers.find((borrower) => borrower.id === selectedBorrowerId);

    const handleBorrowerChange = (event) => {
        setSelectedBorrowerId(event.target.value);
    };

    const handleYearsChange = (event) => {
        const years = parseInt(event.target.value);
        updateIncomeAndExpenses(selectedBorrowerId, null, 'years', years);
    };

    const handleIncomeAndExpensesChange = (index, field, value) => {
        updateIncomeAndExpenses(selectedBorrowerId, index, field, value);
    };

    const aggregateData = (field) => {
        if (!proforma) {
            return null; // We'll handle non-proforma mode in renderYearData
        }

        const years = Math.max(...borrowers.map(b => b.incomeAndExpenses?.years || 0));
        return Array(years).fill().map((_, yearIndex) => {
            return borrowers.reduce((sum, borrower) => {
                const borrowerData = borrower.incomeAndExpenses?.data || [];
                return sum + (parseFloat(borrowerData[yearIndex]?.[field]) || 0);
            }, 0);
        });
    };

    const renderYearHeaders = () => {
        const years = selectedBorrower?.incomeAndExpenses?.years || 0;
        const mostRecentTaxYear = parseInt(loanData?.mostRecentYear) || new Date().getFullYear();
        const columnCount = proforma ? years + 3 : years + 2;

        return Array.from({length: years}).map((_, index) => {
            const year = mostRecentTaxYear - (years - 1 - index);
            return (
                <Grid item xs={12 / columnCount} key={`year-header-${index}`}>
                    <Typography variant="subtitle1">{year}</Typography>
                </Grid>
            );
        });
    };

    const renderYearData = (field) => {
        let years, data, columnCount;

        if (proforma) {
            data = aggregateData(field);
            years = selectedBorrower?.incomeAndExpenses?.years || 0;
            columnCount = years + 3;
        } else {
            years = selectedBorrower?.incomeAndExpenses?.years || 0;
            data = selectedBorrower?.incomeAndExpenses?.data || [];
            columnCount = years + 2;
        }

        // Ensure data is filled for all years
        const filledData = Array.from({length: years}, (_, index) => {
            if (proforma) {
                return data[index] || 0;
            } else {
                return data.find((entry, dataIndex) => dataIndex === index) || {};
            }
        });

        return filledData.map((yearData, index) => {
            const value = proforma ? yearData : (yearData[field] || '');

            return (
                <Grid item xs={12 / columnCount} key={`${field}-${index}`}>
                    <CurrencyTextField
                        variant="outlined"
                        value={value}
                        onChange={(event) => {
                            if (!proforma) {
                                handleIncomeAndExpensesChange(index, field, parseFloat(event.target.value.replace(/,/g, "")) || "")
                            }
                        }}
                        fullWidth
                        InputProps={{
                            readOnly: proforma,
                        }}
                        currencySymbol="$"
                        decimalPlaces={2}
                    />
                </Grid>
            );
        });
    };

    const renderAverage = (field) => {
        const data = proforma ? aggregateData(field) : (selectedBorrower?.incomeAndExpenses?.data || []);
        const years = selectedBorrower?.incomeAndExpenses?.years || 0;
        const average = data.reduce((sum, value) => {
            const fieldValue = proforma ? value : (value[field] || 0);
            return sum + parseFloat(fieldValue);
        }, 0) / (years || 1);
        const columnCount = proforma ? years + 3 : years + 2;

        return (
            <Grid item xs={12 / columnCount}>
                <CurrencyTextField variant="outlined" value={average.toFixed(2)} fullWidth
                                   InputProps={{readOnly: true}}/>
            </Grid>
        );
    };

    const renderProforma = (field) => {
        const proformaValue = loanDetailStore.loanData.incomeAndExpensesProforma?.[field] || '';

        return (
            <Grid item xs={12 / ((selectedBorrower?.incomeAndExpenses?.years || 0) + 3)}>
                <CurrencyTextField
                    variant="outlined"
                    value={proformaValue}
                    onChange={(event) =>
                        updateIncomeAndExpensesProforma(field, parseFloat(event.target.value.replace(/,/g, "")) || "")
                    }
                    fullWidth
                />
            </Grid>
        );
    };

    const renderProformaSum = (fields) => {
        const proformaData = loanDetailStore.loanData.incomeAndExpensesProforma || {};
        const sum = fields.reduce((acc, field) => acc + (parseFloat(proformaData[field]) || 0), 0);
        const years = selectedBorrower?.incomeAndExpenses?.years || 0;
        const columnCount = proforma ? years + 3 : years + 2;

        return (
            <Grid item xs={12 / columnCount}>
                <Typography variant="subtitle1">{sum.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD'
                })}</Typography>
            </Grid>
        );
    };

    const renderProformaOnlyField = (field, label) => {
        if (!proforma) return null;
        const years = selectedBorrower?.incomeAndExpenses?.years || 0;
        return (
            <>
                <Grid item xs={12 / (selectedBorrower?.incomeAndExpenses?.years + 3)}>
                    <Typography variant="subtitle1">{label}:</Typography>
                </Grid>
                {Array(years + 1).fill(null).map((_, index) => (
                    <Grid item xs={12 / (years + 3)} key={`${field}-${index}`}/>
                ))}
                {renderProforma(field)}
            </>
        );
    };

    const calculateTotalCashFlowAvailable = (data) => {
        if (!data) return 0;

        const totalPreTaxIncome = parseFloat(data.totalPreTaxIncome) || 0;
        const plusAdjustments =
            (parseFloat(data.farmDepreciation) || 0) +
            (parseFloat(data.nonFarmDepreciation) || 0) +
            (parseFloat(data.farmCurrentNonCurrentIntF) || 0) +
            (parseFloat(data.nonFarmCurrentNonCurrentIntEE) || 0) +
            (parseFloat(data.capitalLeasePayments) || 0) +
            (parseFloat(data.otherPlusAdjustment) || 0);
        const minusAdjustments =
            (parseFloat(data.incomeAndFICATaxes) || 0) +
            (parseFloat(data.familyLiving) || 0) +
            (parseFloat(data.nonRecurring) || 0) +
            (parseFloat(data.otherMinusAdjustments) || 0);

        return totalPreTaxIncome + plusAdjustments - minusAdjustments;
    };

// Update the calculateProformaExcessCashAvailable function
    const calculateProformaExcessCashAvailable = () => {
        const proformaData = loanDetailStore.loanData.incomeAndExpensesProforma || {};
        const totalCashFlowAvailable = calculateTotalCashFlowAvailable(proformaData);
        const totalRequiredCashFlow =
            (parseFloat(proformaData.totalAnnualPI) || 0) +
            (parseFloat(proformaData.annualCapitalLeasePayments) || 0);
        return totalCashFlowAvailable - totalRequiredCashFlow;
    };

// Update the calculateProformaDebtCoverage function
    const calculateProformaDebtCoverage = () => {
        const proformaData = loanDetailStore.loanData.incomeAndExpensesProforma || {};
        const totalCashFlowAvailable = calculateTotalCashFlowAvailable(proformaData);
        const totalRequiredCashFlow =
            (parseFloat(proformaData.totalAnnualPI) || 0) +
            (parseFloat(proformaData.annualCapitalLeasePayments) || 0);
        return totalRequiredCashFlow !== 0 ? totalCashFlowAvailable / totalRequiredCashFlow : 0;
    };

// Update the calculateProformaTdcShock function
    const calculateProformaTdcShock = (shockFactor) => {
        const proformaData = loanDetailStore.loanData.incomeAndExpensesProforma || {};
        const cashFlowAvailable = calculateTotalCashFlowAvailable(proformaData);
        const totalRequiredCashFlow =
            (parseFloat(proformaData.totalAnnualPI) || 0) +
            (parseFloat(proformaData.annualCapitalLeasePayments) || 0);
        return totalRequiredCashFlow !== 0 ? (cashFlowAvailable * shockFactor) / totalRequiredCashFlow : 0;
    };

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" mb={2}>
            <Typography variant="h6" mb={1}>
                Income and Expenses
            </Typography>
            <Grid container spacing={2} my={1}>
                {!proforma && (
                    <Grid item xs={12} md={4}>
                        <TextField
                            select
                            label="Select Borrower"
                            value={selectedBorrowerId}
                            onChange={handleBorrowerChange}
                            fullWidth
                        >
                            {borrowers.map((borrower) => {
                                let name;
                                switch (borrower.type) {
                                    case 'Trust':
                                        name = borrower.trustName;
                                        break;
                                    case 'Entity':
                                        name = borrower.entityName;
                                        break;
                                    case 'Individual':
                                        name = `${borrower.firstName} ${borrower.lastName}`;
                                        break;
                                    default:
                                        name = 'Unknown';
                                }
                                return (
                                    <MenuItem key={borrower.id} value={borrower.id}>
                                        {name}
                                    </MenuItem>
                                );
                            })}
                        </TextField>
                    </Grid>
                )}
                <Grid item xs={12} md={4}>
                    <TextField
                        label="Most Recent Tax Year"
                        value={loanData?.mostRecentYear || ''}
                        fullWidth
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>
                {proforma && (
                    <Grid item xs={12} md={4}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={loanData.incomeAndExpensesProforma?.costBasisUsed || false}
                                    onChange={(event) => updateIncomeAndExpensesProforma('costBasisUsed', event.target.checked)}
                                    color="primary"
                                />
                            }
                            label="Cost Basis Used"
                        />
                    </Grid>
                )}
                {!proforma && selectedBorrower && (
                    <>
                        <Grid item xs={12} md={4}>
                            <TextField
                                select
                                label="Years"
                                value={selectedBorrower.incomeAndExpenses?.years || ''}
                                onChange={handleYearsChange}
                                disabled={proforma}
                                fullWidth
                            >
                                {[1, 2, 3, 4].map((year) => (
                                    <MenuItem key={year} value={year}>
                                        {year}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </>
                )}
            </Grid>
            <Box my={2}>
                <Divider/>
            </Box>
            <Typography variant="h6" mb={1}>
                Farm Income
            </Typography>
            {!proforma && (
                <Grid container spacing={2} mb={1}>
                    <Grid item xs={12}>
                        <TextField
                            select
                            label="Source"
                            value={selectedBorrower?.incomeAndExpenses?.source || ''}
                            fullWidth
                            onChange={(event) => updateIncomeAndExpenses(selectedBorrowerId, null, 'source', event.target.value)}
                        >
                            <MenuItem value="Tax Return">Tax Return</MenuItem>
                            <MenuItem value="Internally Prepared P/L">Internally Prepared P/L</MenuItem>
                            <MenuItem value="Reviewed/Audited Financial Statement">Reviewed/Audited Financial
                                Statement</MenuItem>
                        </TextField>
                    </Grid>
                </Grid>
            )}
            <Grid container spacing={2}>
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">Year</Typography>
                </Grid>
                {renderYearHeaders()}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">Average</Typography>
                </Grid>
                {proforma && (
                    <Grid item xs={12 / ((selectedBorrower?.incomeAndExpenses?.years || 0) + 3)}>
                        <Typography variant="subtitle1">Proforma</Typography>
                    </Grid>
                )}

                {/* Net Purchased LS & Other */}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">Net Purchased LS & Other</Typography>
                </Grid>
                {renderYearData('netPurchasedLS')}
                {renderAverage('netPurchasedLS')}
                {proforma && renderProforma('netPurchasedLS')}
                {/* Raised Farm Products */}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">Raised Farm Products</Typography>
                </Grid>
                {renderYearData('raisedFarmProducts')}
                {renderAverage('raisedFarmProducts')}
                {proforma && renderProforma('raisedFarmProducts')}

                {/* Cooperative Distributions */}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">Cooperative Distributions</Typography>
                </Grid>
                {renderYearData('cooperativeDistributions')}
                {renderAverage('cooperativeDistributions')}
                {proforma && renderProforma('cooperativeDistributions')}

                {/* Ag Program Payments */}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">Ag Program Payments</Typography>
                </Grid>
                {renderYearData('agProgramPayments')}
                {renderAverage('agProgramPayments')}
                {proforma && renderProforma('agProgramPayments')}

                {/* CCC Loans */}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">CCC Loans</Typography>
                </Grid>
                {renderYearData('cccLoans')}
                {renderAverage('cccLoans')}
                {proforma && renderProforma('cccLoans')}

                {/* Crop Insurance */}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">Crop Insurance</Typography>
                </Grid>
                {renderYearData('cropInsurance')}
                {renderAverage('cropInsurance')}
                {proforma && renderProforma('cropInsurance')}

                {/* Custom Hire */}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">Custom Hire</Typography>
                </Grid>
                {renderYearData('customHire')}
                {renderAverage('customHire')}
                {proforma && renderProforma('customHire')}

                {/* Other Income A */}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">Other Income A</Typography>
                </Grid>
                {renderYearData('farmOtherIncomeA')}
                {renderAverage('farmOtherIncomeA')}
                {proforma && renderProforma('farmOtherIncomeA')}

                {/* Other Income B */}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">Other Income B</Typography>
                </Grid>
                {renderYearData('farmOtherIncomeB')}
                {renderAverage('farmOtherIncomeB')}
                {proforma && renderProforma('farmOtherIncomeB')}

                {proforma && (<>
                    {/* Income From New Property */}
                    <Grid item
                          xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                        <Typography variant="subtitle1">Income From New Property</Typography>
                    </Grid>
                    {renderYearData('incomeFromNewProperty')}
                    {renderAverage('incomeFromNewProperty')}
                    {proforma && renderProforma('incomeFromNewProperty')}

                    {/* Eliminations on consolidation */}
                    <Grid item
                          xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                        <Typography variant="subtitle1">Eliminations on consolidation</Typography>
                    </Grid>
                    {renderYearData('eliminationsOnConsolidation')}
                    {renderAverage('eliminationsOnConsolidation')}
                    {proforma && renderProforma('eliminationsOnConsolidation')}
                </>)
                }

                {/* Total Farm Income */}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>
                        Total Farm Income</Typography>
                </Grid>
                {Array.from({length: selectedBorrower?.incomeAndExpenses?.years || 0}).map((_, index) => {
                    const yearIndex = selectedBorrower?.incomeAndExpenses?.years - 1 - index;
                    const data = Array.isArray(selectedBorrower?.incomeAndExpenses?.data) ? selectedBorrower.incomeAndExpenses.data.slice().reverse() : [];
                    const totalFarmIncome =
                        (parseFloat(data[yearIndex]?.netPurchasedLS) || 0) +
                        (parseFloat(data[yearIndex]?.raisedFarmProducts) || 0) +
                        (parseFloat(data[yearIndex]?.cooperativeDistributions) || 0) +
                        (parseFloat(data[yearIndex]?.agProgramPayments) || 0) +
                        (parseFloat(data[yearIndex]?.cccLoans) || 0) +
                        (parseFloat(data[yearIndex]?.cropInsurance) || 0) +
                        (parseFloat(data[yearIndex]?.customHire) || 0) +
                        (parseFloat(data[yearIndex]?.farmOtherIncomeA) || 0) +
                        (parseFloat(data[yearIndex]?.farmOtherIncomeB) || 0) +
                        (parseFloat(data[yearIndex]?.incomeFromNewProperty) || 0) +
                        (parseFloat(data[yearIndex]?.eliminationsOnConsolidation) || 0);

                    return (
                        <Grid item
                              xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}
                              key={`totalFarmIncome-${index}`}>
                            <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>
                                {totalFarmIncome.toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                })}</Typography>
                        </Grid>
                    );
                })}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>
                        {Array.isArray(selectedBorrower?.incomeAndExpenses?.data) ?
                            (
                                selectedBorrower.incomeAndExpenses.data.slice(0, selectedBorrower?.incomeAndExpenses?.years || 0)
                                    .reduce((sum, year) => sum +
                                        (parseFloat(year?.farmIncome) || 0) +
                                        (parseFloat(year?.netPurchasedLS) || 0) +
                                        (parseFloat(year?.raisedFarmProducts) || 0) +
                                        (parseFloat(year?.cooperativeDistributions) || 0) +
                                        (parseFloat(year?.agProgramPayments) || 0) +
                                        (parseFloat(year?.cccLoans) || 0) +
                                        (parseFloat(year?.cropInsurance) || 0) +
                                        (parseFloat(year?.customHire) || 0) +
                                        (parseFloat(year?.farmOtherIncomeA) || 0) +
                                        (parseFloat(year?.farmOtherIncomeB) || 0), 0) / (selectedBorrower?.incomeAndExpenses?.years || 1) || 0
                            ).toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            }) : '$0.00'}
                    </Typography>
                </Grid>
                {proforma && renderProformaSum(['netPurchasedLS', 'raisedFarmProducts', 'cooperativeDistributions', 'agProgramPayments', 'cccLoans', 'cropInsurance', 'customHire', 'otherIncomeA', 'otherIncomeB', 'incomeFromNewProperty', 'eliminationsOnConsolidation'])}
            </Grid>
            <Box my={2}>
                <Divider/>
            </Box>
            <Typography variant="h6" mb={1}>
                Farm Expenses
            </Typography>
            <Grid container spacing={2}>
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">Year</Typography>
                </Grid>
                {renderYearHeaders()}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">Average</Typography>
                </Grid>
                {proforma && (
                    <Grid item xs={12 / ((selectedBorrower?.incomeAndExpenses?.years || 0) + 3)}>
                        <Typography variant="subtitle1">Proforma</Typography>
                    </Grid>
                )}

                {/* Car & Truck */}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">Car & Truck</Typography>
                </Grid>
                {renderYearData('carAndTruck')}
                {renderAverage('carAndTruck')}
                {proforma && renderProforma('carAndTruck')}

                {/* Chemicals */}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">Chemicals</Typography>
                </Grid>
                {renderYearData('chemicals')}
                {renderAverage('chemicals')}
                {proforma && renderProforma('chemicals')}

                {/* Conservation */}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">Conservation</Typography>
                </Grid>
                {renderYearData('conservation')}
                {renderAverage('conservation')}
                {proforma && renderProforma('conservation')}

                {/* Custom Hire */}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">Custom Hire</Typography>
                </Grid>
                {renderYearData('customHire')}
                {renderAverage('customHire')}
                {proforma && renderProforma('customHire')}

                {/* Depreciation */}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">Depreciation</Typography>
                </Grid>
                {renderYearData('depreciation')}
                {renderAverage('depreciation')}
                {proforma && renderProforma('depreciation')}

                {/* Feed */}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">Feed</Typography>
                </Grid>
                {renderYearData('feed')}
                {renderAverage('feed')}
                {proforma && renderProforma('feed')}

                {/* Fertilizer */}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">Fertilizer</Typography>
                </Grid>
                {renderYearData('fertilizer')}
                {renderAverage('fertilizer')}
                {proforma && renderProforma('fertilizer')}

                {/* Freight */}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">Freight</Typography>
                </Grid>
                {renderYearData('freight')}
                {renderAverage('freight')}
                {proforma && renderProforma('freight')}

                {/* Gasoline/Fuel */}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">Gasoline/Fuel</Typography>
                </Grid>
                {renderYearData('gasolineFuel')}
                {renderAverage('gasolineFuel')}
                {proforma && renderProforma('gasolineFuel')}

                {/* Insurance */}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">Insurance</Typography>
                </Grid>
                {renderYearData('insurance')}
                {renderAverage('insurance')}
                {proforma && renderProforma('insurance')}

                {/* Interest - Mortgage/Non-Current */}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">Interest - Mortgage/Non-Current</Typography>
                </Grid>
                {renderYearData('interestMortgageNonCurrent')}
                {renderAverage('interestMortgageNonCurrent')}
                {proforma && renderProforma('interestMortgageNonCurrent')}

                {/* Other Interest */}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">Other Interest</Typography>
                </Grid>
                {renderYearData('otherInterest')}
                {renderAverage('otherInterest')}
                {proforma && renderProforma('otherInterest')}

                {/* Current Labor */}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">Current Labor</Typography>
                </Grid>
                {renderYearData('currentLabor')}
                {renderAverage('currentLabor')}
                {proforma && renderProforma('currentLabor')}

                {/* Rent/Lease, Machinery */}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">Rent/Lease, Machinery</Typography>
                </Grid>
                {renderYearData('rentLeaseMachinery')}
                {renderAverage('rentLeaseMachinery')}
                {proforma && renderProforma('rentLeaseMachinery')}

                {/* Rent/Lease, Real Property */}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">Rent/Lease, Real Property</Typography>
                </Grid>
                {renderYearData('rentLeaseRealProperty')}
                {renderAverage('rentLeaseRealProperty')}
                {proforma && renderProforma('rentLeaseRealProperty')}

                {/* Repairs */}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">Repairs</Typography>
                </Grid>
                {renderYearData('repairs')}
                {renderAverage('repairs')}
                {proforma && renderProforma('repairs')}

                {/* Seed */}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">Seed</Typography>
                </Grid>
                {renderYearData('seed')}
                {renderAverage('seed')}
                {proforma && renderProforma('seed')}

                {/* Storage */}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">Storage</Typography>
                </Grid>
                {renderYearData('storage')}
                {renderAverage('storage')}
                {proforma && renderProforma('storage')}

                {/* Supplies */}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">Supplies</Typography>
                </Grid>
                {renderYearData('supplies')}
                {renderAverage('supplies')}
                {proforma && renderProforma('supplies')}

                {/* Taxes */}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">Taxes</Typography>
                </Grid>
                {renderYearData('taxes')}
                {renderAverage('taxes')}
                {proforma && renderProforma('taxes')}

                {/* Utilities */}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">Utilities</Typography>
                </Grid>
                {renderYearData('utilities')}
                {renderAverage('utilities')}
                {proforma && renderProforma('utilities')}

                {/* Veterinary Fees */}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">Veterinary Fees</Typography>
                </Grid>
                {renderYearData('veterinaryFees')}
                {renderAverage('veterinaryFees')}
                {proforma && renderProforma('veterinaryFees')}

                {/* Other Expenses A */}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">Other Expenses A</Typography>
                </Grid>
                {renderYearData('otherExpensesA')}
                {renderAverage('otherExpensesA')}
                {proforma && renderProforma('otherExpensesA')}

                {/* Other Expenses B */}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">Other Expenses B</Typography>
                </Grid>
                {renderYearData('otherExpensesB')}
                {renderAverage('otherExpensesB')}
                {proforma && renderProforma('otherExpensesB')}

                {/* New fields: Expense from New Property and Elimination on Consolidation */}
                {renderProformaOnlyField('expenseFromNewProperty', 'Expense from New Property')}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">Elimination on Consolidation</Typography>
                </Grid>
                {renderYearData('eliminationOnConsolidation', 'Elimination on Consolidation')}
                {renderAverage('eliminationOnConsolidation')}
                {proforma && renderProforma('eliminationOnConsolidation')}

                {/* Total Farm Expenses */}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>
                        Total Farm Expenses</Typography>
                </Grid>
                {Array.from({length: selectedBorrower?.incomeAndExpenses?.years || 0}).map((_, index) => {
                    const yearIndex = selectedBorrower?.incomeAndExpenses?.years - 1 - index;
                    const data = Array.isArray(selectedBorrower?.incomeAndExpenses?.data) ? selectedBorrower.incomeAndExpenses.data.slice().reverse() : [];
                    const totalFarmExpenses =
                        (parseFloat(data[yearIndex]?.carAndTruck) || 0) +
                        (parseFloat(data[yearIndex]?.chemicals) || 0) +
                        (parseFloat(data[yearIndex]?.conservation) || 0) +
                        (parseFloat(data[yearIndex]?.customHire) || 0) +
                        (parseFloat(data[yearIndex]?.depreciation) || 0) +
                        (parseFloat(data[yearIndex]?.feed) || 0) +
                        (parseFloat(data[yearIndex]?.fertilizer) || 0) +
                        (parseFloat(data[yearIndex]?.freight) || 0) +
                        (parseFloat(data[yearIndex]?.gasolineFuel) || 0) +
                        (parseFloat(data[yearIndex]?.insurance) || 0) +
                        (parseFloat(data[yearIndex]?.interestMortgageNonCurrent) || 0) +
                        (parseFloat(data[yearIndex]?.otherInterest) || 0) +
                        (parseFloat(data[yearIndex]?.currentLabor) || 0) +
                        (parseFloat(data[yearIndex]?.rentLeaseMachinery) || 0) +
                        (parseFloat(data[yearIndex]?.rentLeaseRealProperty) || 0) +
                        (parseFloat(data[yearIndex]?.repairs) || 0) +
                        (parseFloat(data[yearIndex]?.seed) || 0) +
                        (parseFloat(data[yearIndex]?.savings) || 0) +
                        (parseFloat(data[yearIndex]?.supplies) || 0) +
                        (parseFloat(data[yearIndex]?.taxes) || 0) +
                        (parseFloat(data[yearIndex]?.utilities) || 0) +
                        (parseFloat(data[yearIndex]?.veterinaryFees) || 0) +
                        (parseFloat(data[yearIndex]?.otherExpensesA) || 0) +
                        (parseFloat(data[yearIndex]?.otherExpensesB) || 0) +
                        (parseFloat(data[yearIndex]?.expenseFromNewProperty) || 0) +
                        (parseFloat(data[yearIndex]?.eliminationOnConsolidation) || 0);

                    return (
                        <Grid item
                              xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}
                              key={`totalFarmExpenses-${index}`}>
                            <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>
                                {totalFarmExpenses.toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                })}</Typography>
                        </Grid>
                    );
                })}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>
                        {Array.isArray(selectedBorrower?.incomeAndExpenses?.data) ?
                            (
                                selectedBorrower.incomeAndExpenses.data.slice(0, selectedBorrower?.incomeAndExpenses?.years || 0)
                                    .reduce((sum, year) => sum +
                                        (parseFloat(year?.carAndTruck) || 0) +
                                        (parseFloat(year?.chemicals) || 0) +
                                        (parseFloat(year?.conservation) || 0) +
                                        (parseFloat(year?.customHire) || 0) +
                                        (parseFloat(year?.depreciation) || 0) +
                                        (parseFloat(year?.feed) || 0) +
                                        (parseFloat(year?.fertilizer) || 0) +
                                        (parseFloat(year?.freight) || 0) +
                                        (parseFloat(year?.gasolineFuel) || 0) +
                                        (parseFloat(year?.insurance) || 0) +
                                        (parseFloat(year?.interestMortgageNonCurrent) || 0) +
                                        (parseFloat(year?.otherInterest) || 0) +
                                        (parseFloat(year?.currentLabor) || 0) +
                                        (parseFloat(year?.rentLeaseMachinery) || 0) +
                                        (parseFloat(year?.rentLeaseRealProperty) || 0) +
                                        (parseFloat(year?.repairs) || 0) +
                                        (parseFloat(year?.seed) || 0) +
                                        (parseFloat(year?.savings) || 0) +
                                        (parseFloat(year?.supplies) || 0) +
                                        (parseFloat(year?.taxes) || 0) +
                                        (parseFloat(year?.utilities) || 0) +
                                        (parseFloat(year?.veterinaryFees) || 0) +
                                        (parseFloat(year?.otherExpensesA) || 0) +
                                        (parseFloat(year?.otherExpensesB) || 0) +
                                        (parseFloat(year?.expenseFromNewProperty) || 0) +
                                        (parseFloat(year?.eliminationOnConsolidation) || 0), 0) / (selectedBorrower?.incomeAndExpenses?.years || 1) || 0
                            ).toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            }) : '$0.00'}
                    </Typography>
                </Grid>
                {proforma && renderProformaSum(['carAndTruck', 'chemicals', 'conservation', 'customHire', 'depreciation', 'feed', 'fertilizer', 'freight', 'gasolineFuel', 'insurance', 'interestMortgageNonCurrent', 'otherInterest', 'currentLabor', 'rentLeaseMachinery', 'rentLeaseRealProperty', 'repairs', 'seed', 'savings', 'supplies', 'taxes', 'utilities', 'veterinaryFees', 'otherExpensesA', 'otherExpensesB', 'expenseFromNewProperty', 'eliminationOnConsolidation'])}


                {/* Net Farm Income */}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>
                        Net Farm Income</Typography>
                </Grid>
                {Array.from({length: selectedBorrower?.incomeAndExpenses?.years || 0}).map((_, index) => {
                    const yearIndex = selectedBorrower?.incomeAndExpenses?.years - 1 - index;
                    const data = Array.isArray(selectedBorrower?.incomeAndExpenses?.data) ? selectedBorrower.incomeAndExpenses.data.slice().reverse() : [];
                    const totalFarmIncome =
                        (parseFloat(data[yearIndex]?.farmIncome) || 0) +
                        (parseFloat(data[yearIndex]?.netPurchasedLS) || 0) +
                        (parseFloat(data[yearIndex]?.raisedFarmProducts) || 0) +
                        (parseFloat(data[yearIndex]?.cooperativeDistributions) || 0) +
                        (parseFloat(data[yearIndex]?.agProgramPayments) || 0) +
                        (parseFloat(data[yearIndex]?.cccLoans) || 0) +
                        (parseFloat(data[yearIndex]?.cropInsurance) || 0) +
                        (parseFloat(data[yearIndex]?.customHire) || 0) +
                        (parseFloat(data[yearIndex]?.farmOtherIncomeA) || 0) +
                        (parseFloat(data[yearIndex]?.farmOtherIncomeB) || 0);
                    const totalFarmExpenses =
                        (parseFloat(data[yearIndex]?.carAndTruck) || 0) +
                        (parseFloat(data[yearIndex]?.chemicals) || 0) +
                        (parseFloat(data[yearIndex]?.conservation) || 0) +
                        (parseFloat(data[yearIndex]?.customHire) || 0) +
                        (parseFloat(data[yearIndex]?.depreciation) || 0) +
                        (parseFloat(data[yearIndex]?.feed) || 0) +
                        (parseFloat(data[yearIndex]?.fertilizer) || 0) +
                        (parseFloat(data[yearIndex]?.freight) || 0) +
                        (parseFloat(data[yearIndex]?.gasolineFuel) || 0) +
                        (parseFloat(data[yearIndex]?.insurance) || 0) +
                        (parseFloat(data[yearIndex]?.interestMortgageNonCurrent) || 0) +
                        (parseFloat(data[yearIndex]?.otherInterest) || 0) +
                        (parseFloat(data[yearIndex]?.currentLabor) || 0) +
                        (parseFloat(data[yearIndex]?.rentLeaseMachinery) || 0) +
                        (parseFloat(data[yearIndex]?.rentLeaseRealProperty) || 0) +
                        (parseFloat(data[yearIndex]?.repairs) || 0) +
                        (parseFloat(data[yearIndex]?.seed) || 0) +
                        (parseFloat(data[yearIndex]?.savings) || 0) +
                        (parseFloat(data[yearIndex]?.supplies) || 0) +
                        (parseFloat(data[yearIndex]?.taxes) || 0) +
                        (parseFloat(data[yearIndex]?.utilities) || 0) +
                        (parseFloat(data[yearIndex]?.veterinaryFees) || 0) +
                        (parseFloat(data[yearIndex]?.otherExpensesA) || 0) +
                        (parseFloat(data[yearIndex]?.otherExpensesB) || 0);
                    const netFarmIncome = totalFarmIncome - totalFarmExpenses;

                    return (
                        <Grid item
                              xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}
                              key={`netFarmIncome-${index}`}>
                            <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>
                                {netFarmIncome.toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                })}</Typography>
                        </Grid>
                    );
                })}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>
                        {Array.isArray(selectedBorrower?.incomeAndExpenses?.data) ?
                            (
                                selectedBorrower.incomeAndExpenses.data.slice(0, selectedBorrower?.incomeAndExpenses?.years || 0)
                                    .reduce((sum, year) => {
                                        const totalFarmIncome =
                                            (parseFloat(year?.farmIncome) || 0) +
                                            (parseFloat(year?.netPurchasedLS) || 0) +
                                            (parseFloat(year?.raisedFarmProducts) || 0) +
                                            (parseFloat(year?.cooperativeDistributions) || 0) +
                                            (parseFloat(year?.agProgramPayments) || 0) +
                                            (parseFloat(year?.cccLoans) || 0) +
                                            (parseFloat(year?.cropInsurance) || 0) +
                                            (parseFloat(year?.customHire) || 0) +
                                            (parseFloat(year?.farmOtherIncomeA) || 0) +
                                            (parseFloat(year?.farmOtherIncomeB) || 0);
                                        const totalFarmExpenses =
                                            (parseFloat(year?.carAndTruck) || 0) +
                                            (parseFloat(year?.chemicals) || 0) +
                                            (parseFloat(year?.conservation) || 0) +
                                            (parseFloat(year?.customHire) || 0) +
                                            (parseFloat(year?.depreciation) || 0) +
                                            (parseFloat(year?.feed) || 0) +
                                            (parseFloat(year?.fertilizer) || 0) +
                                            (parseFloat(year?.freight) || 0) +
                                            (parseFloat(year?.gasolineFuel) || 0) +
                                            (parseFloat(year?.insurance) || 0) +
                                            (parseFloat(year?.interestMortgageNonCurrent) || 0) +
                                            (parseFloat(year?.otherInterest) || 0) +
                                            (parseFloat(year?.currentLabor) || 0) +
                                            (parseFloat(year?.rentLeaseMachinery) || 0) +
                                            (parseFloat(year?.rentLeaseRealProperty) || 0) +
                                            (parseFloat(year?.repairs) || 0) +
                                            (parseFloat(year?.seed) || 0) +
                                            (parseFloat(year?.savings) || 0) +
                                            (parseFloat(year?.supplies) || 0) +
                                            (parseFloat(year?.taxes) || 0) +
                                            (parseFloat(year?.utilities) || 0) +
                                            (parseFloat(year?.veterinaryFees) || 0) +
                                            (parseFloat(year?.otherExpensesA) || 0) +
                                            (parseFloat(year?.otherExpensesB) || 0);
                                        return sum + (totalFarmIncome - totalFarmExpenses);
                                    }, 0) / (selectedBorrower?.incomeAndExpenses?.years || 1) || 0
                            ).toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            }) : '$0.00'}
                    </Typography>
                </Grid>
                {proforma && (
                    <Grid item xs={12 / ((selectedBorrower?.incomeAndExpenses?.years || 0) + 3)}>
                        <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>
                            {(() => {
                                const proformaData = loanDetailStore.loanData.incomeAndExpensesProforma || {};
                                const totalFarmIncome =
                                    (parseFloat(proformaData.netPurchasedLS) || 0) +
                                    (parseFloat(proformaData.raisedFarmProducts) || 0) +
                                    (parseFloat(proformaData.cooperativeDistributions) || 0) +
                                    (parseFloat(proformaData.agProgramPayments) || 0) +
                                    (parseFloat(proformaData.cccLoans) || 0) +
                                    (parseFloat(proformaData.cropInsurance) || 0) +
                                    (parseFloat(proformaData.customHire) || 0) +
                                    (parseFloat(proformaData.farmOtherIncomeA) || 0) +
                                    (parseFloat(proformaData.farmOtherIncomeB) || 0) +
                                    (parseFloat(proformaData.incomeFromNewProperty) || 0) +
                                    (parseFloat(proformaData.eliminationsOnConsolidation) || 0);
                                const totalFarmExpenses =
                                    (parseFloat(proformaData.carAndTruck) || 0) +
                                    (parseFloat(proformaData.chemicals) || 0) +
                                    (parseFloat(proformaData.conservation) || 0) +
                                    (parseFloat(proformaData.customHire) || 0) +
                                    (parseFloat(proformaData.depreciation) || 0) +
                                    (parseFloat(proformaData.feed) || 0) +
                                    (parseFloat(proformaData.fertilizer) || 0) +
                                    (parseFloat(proformaData.freight) || 0) +
                                    (parseFloat(proformaData.gasolineFuel) || 0) +
                                    (parseFloat(proformaData.insurance) || 0) +
                                    (parseFloat(proformaData.interestMortgageNonCurrent) || 0) +
                                    (parseFloat(proformaData.otherInterest) || 0) +
                                    (parseFloat(proformaData.currentLabor) || 0) +
                                    (parseFloat(proformaData.rentLeaseMachinery) || 0) +
                                    (parseFloat(proformaData.rentLeaseRealProperty) || 0) +
                                    (parseFloat(proformaData.repairs) || 0) +
                                    (parseFloat(proformaData.seed) || 0) +
                                    (parseFloat(proformaData.savings) || 0) +
                                    (parseFloat(proformaData.supplies) || 0) +
                                    (parseFloat(proformaData.taxes) || 0) +
                                    (parseFloat(proformaData.utilities) || 0) +
                                    (parseFloat(proformaData.veterinaryFees) || 0) +
                                    (parseFloat(proformaData.otherExpensesA) || 0) +
                                    (parseFloat(proformaData.otherExpensesB) || 0) +
                                    (parseFloat(proformaData.expenseFromNewProperty) || 0) +
                                    (parseFloat(proformaData.eliminationOnConsolidation) || 0);
                                const netFarmIncome = totalFarmIncome - totalFarmExpenses;
                                return netFarmIncome.toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                });
                            })()}
                        </Typography>
                    </Grid>
                )}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                </Grid>
            </Grid>
            <Box my={2}>
                <Divider/>
            </Box>
            <Typography variant="h6" mb={1}>
                Non-Farm Income
            </Typography>
            <Grid container spacing={2}>
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">Year</Typography>
                </Grid>
                {renderYearHeaders()}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">Average</Typography>
                </Grid>
                {proforma && (
                    <Grid item xs={12 / ((selectedBorrower?.incomeAndExpenses?.years || 0) + 3)}>
                        <Typography variant="subtitle1">Proforma</Typography>
                    </Grid>
                )}

                {/* Salaries */}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">Salaries</Typography>
                </Grid>
                {renderYearData('salaries')}
                {renderAverage('salaries')}
                {proforma && renderProforma('salaries')}

                {/* Interest Income */}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">Interest Income</Typography>
                </Grid>
                {renderYearData('interestIncome')}
                {renderAverage('interestIncome')}
                {proforma && renderProforma('interestIncome')}

                {/* Dividends */}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">Dividends</Typography>
                </Grid>
                {renderYearData('dividends')}
                {renderAverage('dividends')}
                {proforma && renderProforma('dividends')}

                {/* Business Income (Sch C) */}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">Business Income (Sch C)</Typography>
                </Grid>
                {renderYearData('businessIncomeSchC')}
                {renderAverage('businessIncomeSchC')}
                {proforma && renderProforma('businessIncomeSchC')}

                {/* Capital Gain/Loss (Sch D) */}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">Capital Gain/Loss (Sch D)</Typography>
                </Grid>
                {renderYearData('capitalGainLossSchD')}
                {renderAverage('capitalGainLossSchD')}
                {proforma && renderProforma('capitalGainLossSchD')}

                {/* Other Gain/Loss (4797) */}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">Other Gain/Loss (4797)</Typography>
                </Grid>
                {renderYearData('otherGainLoss4797')}
                {renderAverage('otherGainLoss4797')}
                {proforma && renderProforma('otherGainLoss4797')}

                {/* Rental Income (Sch E) */}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">Rental Income (Sch E)</Typography>
                </Grid>
                {renderYearData('rentalIncomeSchE')}
                {renderAverage('rentalIncomeSchE')}
                {proforma && renderProforma('rentalIncomeSchE')}

                {/* Other Income A */}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">Other Income A</Typography>
                </Grid>
                {renderYearData('otherIncomeA')}
                {renderAverage('otherIncomeA')}
                {proforma && renderProforma('otherIncomeA')}

                {/* Other Income B */}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">Other Income B</Typography>
                </Grid>
                {renderYearData('otherIncomeB')}
                {renderAverage('otherIncomeB')}
                {proforma && renderProforma('otherIncomeB')}

                {/* Other Income C */}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">Other Income C</Typography>
                </Grid>
                {renderYearData('otherIncomeC')}
                {renderAverage('otherIncomeC')}
                {proforma && renderProforma('otherIncomeC')}

                {/* Total Non-Farm Income */}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>Total Non-Farm Income</Typography>
                </Grid>
                {Array.from({length: selectedBorrower?.incomeAndExpenses?.years || 0}).map((_, index) => {
                    const yearIndex = selectedBorrower?.incomeAndExpenses?.years - 1 - index;
                    const data = Array.isArray(selectedBorrower?.incomeAndExpenses?.data) ? selectedBorrower.incomeAndExpenses.data.slice().reverse() : [];
                    const totalNonFarmIncome =
                        (parseFloat(data[yearIndex]?.salaries) || 0) +
                        (parseFloat(data[yearIndex]?.interestIncome) || 0) +
                        (parseFloat(data[yearIndex]?.dividends) || 0) +
                        (parseFloat(data[yearIndex]?.businessIncomeSchC) || 0) +
                        (parseFloat(data[yearIndex]?.capitalGainLossSchD) || 0) +
                        (parseFloat(data[yearIndex]?.otherGainLoss4797) || 0) +
                        (parseFloat(data[yearIndex]?.rentalIncomeSchE) || 0) +
                        (parseFloat(data[yearIndex]?.otherIncomeA) || 0) +
                        (parseFloat(data[yearIndex]?.otherIncomeB) || 0) +
                        (parseFloat(data[yearIndex]?.otherIncomeC) || 0);

                    return (
                        <Grid item
                              xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}
                              key={`totalNonFarmIncome-${index}`}>
                            <Typography variant="subtitle1"
                                        sx={{fontWeight: 'bold'}}>{totalNonFarmIncome.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            })}</Typography>
                        </Grid>
                    );
                })}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>
                        {Array.isArray(selectedBorrower?.incomeAndExpenses?.data) ?
                            (
                                selectedBorrower.incomeAndExpenses.data.slice(0, selectedBorrower?.incomeAndExpenses?.years || 0)
                                    .reduce((sum, year) => sum +
                                        (parseFloat(year?.salaries) || 0) +
                                        (parseFloat(year?.interestIncome) || 0) +
                                        (parseFloat(year?.dividends) || 0) +
                                        (parseFloat(year?.businessIncomeSchC) || 0) +
                                        (parseFloat(year?.capitalGainLossSchD) || 0) +
                                        (parseFloat(year?.otherGainLoss4797) || 0) +
                                        (parseFloat(year?.rentalIncomeSchE) || 0) +
                                        (parseFloat(year?.otherIncomeA) || 0) +
                                        (parseFloat(year?.otherIncomeB) || 0) +
                                        (parseFloat(year?.otherIncomeC) || 0), 0) / (selectedBorrower?.incomeAndExpenses?.years || 1) || 0
                            ).toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            }) : '$0.00'}
                    </Typography>
                </Grid>
                {proforma && renderProformaSum(['salaries', 'interestIncome', 'dividends', 'businessIncomeSchC', 'capitalGainLossSchD', 'otherGainLoss4797', 'rentalIncomeSchE', 'otherIncomeA', 'otherIncomeB', 'otherIncomeC'])}


                {/* Total Pre-Tax Income */}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>Total Pre-Tax Income</Typography>
                </Grid>
                {Array.from({length: selectedBorrower?.incomeAndExpenses?.years || 0}).map((_, index) => {
                    const yearIndex = selectedBorrower?.incomeAndExpenses?.years - 1 - index;
                    const data = Array.isArray(selectedBorrower?.incomeAndExpenses?.data) ? selectedBorrower.incomeAndExpenses.data.slice().reverse() : [];
                    const totalFarmIncome =
                        (parseFloat(data[yearIndex]?.farmIncome) || 0) +
                        (parseFloat(data[yearIndex]?.netPurchasedLS) || 0) +
                        (parseFloat(data[yearIndex]?.raisedFarmProducts) || 0) +
                        (parseFloat(data[yearIndex]?.cooperativeDistributions) || 0) +
                        (parseFloat(data[yearIndex]?.agProgramPayments) || 0) +
                        (parseFloat(data[yearIndex]?.cccLoans) || 0) +
                        (parseFloat(data[yearIndex]?.cropInsurance) || 0) +
                        (parseFloat(data[yearIndex]?.customHire) || 0) +
                        (parseFloat(data[yearIndex]?.farmOtherIncomeA) || 0) +
                        (parseFloat(data[yearIndex]?.farmOtherIncomeB) || 0);
                    const totalNonFarmIncome =
                        (parseFloat(data[yearIndex]?.salaries) || 0) +
                        (parseFloat(data[yearIndex]?.interestIncome) || 0) +
                        (parseFloat(data[yearIndex]?.dividends) || 0) +
                        (parseFloat(data[yearIndex]?.businessIncomeSchC) || 0) +
                        (parseFloat(data[yearIndex]?.capitalGainLossSchD) || 0) +
                        (parseFloat(data[yearIndex]?.otherGainLoss4797) || 0) +
                        (parseFloat(data[yearIndex]?.rentalIncomeSchE) || 0) +
                        (parseFloat(data[yearIndex]?.otherIncomeA) || 0) +
                        (parseFloat(data[yearIndex]?.otherIncomeB) || 0) +
                        (parseFloat(data[yearIndex]?.otherIncomeC) || 0);
                    const totalPreTaxIncome = totalFarmIncome + totalNonFarmIncome;

                    return (
                        <Grid item
                              xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}
                              key={`totalPreTaxIncome-${index}`}>
                            <Typography variant="subtitle1"
                                        sx={{fontWeight: 'bold'}}>{totalPreTaxIncome.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            })}</Typography>
                        </Grid>
                    );
                })}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>
                        {Array.isArray(selectedBorrower?.incomeAndExpenses?.data) ?
                            (
                                selectedBorrower.incomeAndExpenses.data.slice(0, selectedBorrower?.incomeAndExpenses?.years || 0)
                                    .reduce((sum, year) => {
                                        const totalFarmIncome =
                                            (parseFloat(year?.farmIncome) || 0) +
                                            (parseFloat(year?.netPurchasedLS) || 0) +
                                            (parseFloat(year?.raisedFarmProducts) || 0) +
                                            (parseFloat(year?.cooperativeDistributions) || 0) +
                                            (parseFloat(year?.agProgramPayments) || 0) +
                                            (parseFloat(year?.cccLoans) || 0) +
                                            (parseFloat(year?.cropInsurance) || 0) +
                                            (parseFloat(year?.customHire) || 0) +
                                            (parseFloat(year?.farmOtherIncomeA) || 0) +
                                            (parseFloat(year?.farmOtherIncomeB) || 0);
                                        const totalNonFarmIncome =
                                            (parseFloat(year?.salaries) || 0) +
                                            (parseFloat(year?.interestIncome) || 0) +
                                            (parseFloat(year?.dividends) || 0) +
                                            (parseFloat(year?.businessIncomeSchC) || 0) +
                                            (parseFloat(year?.capitalGainLossSchD) || 0) +
                                            (parseFloat(year?.otherGainLoss4797) || 0) +
                                            (parseFloat(year?.rentalIncomeSchE) || 0) +
                                            (parseFloat(year?.otherIncomeA) || 0) +
                                            (parseFloat(year?.otherIncomeB) || 0) +
                                            (parseFloat(year?.otherIncomeC) || 0);
                                        return sum + (totalFarmIncome + totalNonFarmIncome);
                                    }, 0) / (selectedBorrower?.incomeAndExpenses?.years || 1) || 0
                            ).toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            }) : '$0.00'}
                    </Typography>
                </Grid>
                {proforma && (
                    <Grid item xs={12 / ((selectedBorrower?.incomeAndExpenses?.years || 0) + 3)}>
                        <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>
                            {(() => {
                                const proformaData = loanDetailStore.loanData.incomeAndExpensesProforma || {};
                                const totalFarmIncome =
                                    (parseFloat(proformaData.netPurchasedLS) || 0) +
                                    (parseFloat(proformaData.raisedFarmProducts) || 0) +
                                    (parseFloat(proformaData.cooperativeDistributions) || 0) +
                                    (parseFloat(proformaData.agProgramPayments) || 0) +
                                    (parseFloat(proformaData.cccLoans) || 0) +
                                    (parseFloat(proformaData.cropInsurance) || 0) +
                                    (parseFloat(proformaData.customHire) || 0) +
                                    (parseFloat(proformaData.farmOtherIncomeA) || 0) +
                                    (parseFloat(proformaData.farmOtherIncomeB) || 0) +
                                    (parseFloat(proformaData.incomeFromNewProperty) || 0) +
                                    (parseFloat(proformaData.eliminationsOnConsolidation) || 0);
                                const totalNonFarmIncome =
                                    (parseFloat(proformaData.salaries) || 0) +
                                    (parseFloat(proformaData.interestIncome) || 0) +
                                    (parseFloat(proformaData.dividends) || 0) +
                                    (parseFloat(proformaData.businessIncomeSchC) || 0) +
                                    (parseFloat(proformaData.capitalGainLossSchD) || 0) +
                                    (parseFloat(proformaData.otherGainLoss4797) || 0) +
                                    (parseFloat(proformaData.rentalIncomeSchE) || 0) +
                                    (parseFloat(proformaData.otherIncomeA) || 0) +
                                    (parseFloat(proformaData.otherIncomeB) || 0) +
                                    (parseFloat(proformaData.otherIncomeC) || 0);
                                const totalPreTaxIncome = totalFarmIncome + totalNonFarmIncome;
                                return totalPreTaxIncome.toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                });
                            })()}
                        </Typography>
                    </Grid>
                )}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}/>
            </Grid>
            <Box my={2}>
                <Divider/>
            </Box>
            <Typography variant="h6" mb={1}>
                Cash Flow Coverage
            </Typography>
            <Grid container spacing={2}>
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">Year</Typography>
                </Grid>
                {renderYearHeaders()}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">Average</Typography>
                </Grid>
                {proforma && (
                    <Grid item xs={12 / ((selectedBorrower?.incomeAndExpenses?.years || 0) + 3)}>
                        <Typography variant="subtitle1">Proforma</Typography>
                    </Grid>
                )}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>Total Pre-Tax Income</Typography>
                </Grid>
                {Array.from({length: selectedBorrower?.incomeAndExpenses?.years || 0}).map((_, index) => {
                    const yearIndex = selectedBorrower?.incomeAndExpenses?.years - 1 - index;
                    const data = Array.isArray(selectedBorrower?.incomeAndExpenses?.data) ? selectedBorrower.incomeAndExpenses.data.slice().reverse() : [];
                    const totalFarmIncome =
                        (parseFloat(data[yearIndex]?.farmIncome) || 0) +
                        (parseFloat(data[yearIndex]?.netPurchasedLS) || 0) +
                        (parseFloat(data[yearIndex]?.raisedFarmProducts) || 0) +
                        (parseFloat(data[yearIndex]?.cooperativeDistributions) || 0) +
                        (parseFloat(data[yearIndex]?.agProgramPayments) || 0) +
                        (parseFloat(data[yearIndex]?.cccLoans) || 0) +
                        (parseFloat(data[yearIndex]?.cropInsurance) || 0) +
                        (parseFloat(data[yearIndex]?.customHire) || 0) +
                        (parseFloat(data[yearIndex]?.farmOtherIncomeA) || 0) +
                        (parseFloat(data[yearIndex]?.farmOtherIncomeB) || 0);
                    const totalNonFarmIncome =
                        (parseFloat(data[yearIndex]?.salaries) || 0) +
                        (parseFloat(data[yearIndex]?.interestIncome) || 0) +
                        (parseFloat(data[yearIndex]?.dividends) || 0) +
                        (parseFloat(data[yearIndex]?.businessIncomeSchC) || 0) +
                        (parseFloat(data[yearIndex]?.capitalGainLossSchD) || 0) +
                        (parseFloat(data[yearIndex]?.otherGainLoss4797) || 0) +
                        (parseFloat(data[yearIndex]?.rentalIncomeSchE) || 0) +
                        (parseFloat(data[yearIndex]?.otherIncomeA) || 0) +
                        (parseFloat(data[yearIndex]?.otherIncomeB) || 0) +
                        (parseFloat(data[yearIndex]?.otherIncomeC) || 0);
                    const totalPreTaxIncome = totalFarmIncome + totalNonFarmIncome;

                    return (
                        <Grid item
                              xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}
                              key={`totalPreTaxIncome-${index}`}>
                            <Typography variant="subtitle1"
                                        sx={{fontWeight: 'bold'}}>{totalPreTaxIncome.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            })}</Typography>
                        </Grid>
                    );
                })}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>
                        {Array.isArray(selectedBorrower?.incomeAndExpenses?.data) ?
                            (
                                selectedBorrower.incomeAndExpenses.data.slice(0, selectedBorrower?.incomeAndExpenses?.years || 0)
                                    .reduce((sum, year) => {
                                        const totalFarmIncome =
                                            (parseFloat(year?.farmIncome) || 0) +
                                            (parseFloat(year?.netPurchasedLS) || 0) +
                                            (parseFloat(year?.raisedFarmProducts) || 0) +
                                            (parseFloat(year?.cooperativeDistributions) || 0) +
                                            (parseFloat(year?.agProgramPayments) || 0) +
                                            (parseFloat(year?.cccLoans) || 0) +
                                            (parseFloat(year?.cropInsurance) || 0) +
                                            (parseFloat(year?.customHire) || 0) +
                                            (parseFloat(year?.farmOtherIncomeA) || 0) +
                                            (parseFloat(year?.farmOtherIncomeB) || 0);
                                        const totalNonFarmIncome =
                                            (parseFloat(year?.salaries) || 0) +
                                            (parseFloat(year?.interestIncome) || 0) +
                                            (parseFloat(year?.dividends) || 0) +
                                            (parseFloat(year?.businessIncomeSchC) || 0) +
                                            (parseFloat(year?.capitalGainLossSchD) || 0) +
                                            (parseFloat(year?.otherGainLoss4797) || 0) +
                                            (parseFloat(year?.rentalIncomeSchE) || 0) +
                                            (parseFloat(year?.otherIncomeA) || 0) +
                                            (parseFloat(year?.otherIncomeB) || 0) +
                                            (parseFloat(year?.otherIncomeC) || 0);
                                        return sum + (totalFarmIncome + totalNonFarmIncome);
                                    }, 0) / (selectedBorrower?.incomeAndExpenses?.years || 1) || 0
                            ).toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            }) : '$0.00'}
                    </Typography>
                </Grid>
                {proforma && (
                    <Grid item xs={12 / ((selectedBorrower?.incomeAndExpenses?.years || 0) + 3)}>
                        <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>
                            {(() => {
                                const proformaData = loanDetailStore.loanData.incomeAndExpensesProforma || {};
                                const totalFarmIncome =
                                    (parseFloat(proformaData.netPurchasedLS) || 0) +
                                    (parseFloat(proformaData.raisedFarmProducts) || 0) +
                                    (parseFloat(proformaData.cooperativeDistributions) || 0) +
                                    (parseFloat(proformaData.agProgramPayments) || 0) +
                                    (parseFloat(proformaData.cccLoans) || 0) +
                                    (parseFloat(proformaData.cropInsurance) || 0) +
                                    (parseFloat(proformaData.customHire) || 0) +
                                    (parseFloat(proformaData.farmOtherIncomeA) || 0) +
                                    (parseFloat(proformaData.farmOtherIncomeB) || 0) +
                                    (parseFloat(proformaData.incomeFromNewProperty) || 0) +
                                    (parseFloat(proformaData.eliminationsOnConsolidation) || 0);
                                const totalNonFarmIncome =
                                    (parseFloat(proformaData.salaries) || 0) +
                                    (parseFloat(proformaData.interestIncome) || 0) +
                                    (parseFloat(proformaData.dividends) || 0) +
                                    (parseFloat(proformaData.businessIncomeSchC) || 0) +
                                    (parseFloat(proformaData.capitalGainLossSchD) || 0) +
                                    (parseFloat(proformaData.otherGainLoss4797) || 0) +
                                    (parseFloat(proformaData.rentalIncomeSchE) || 0) +
                                    (parseFloat(proformaData.otherIncomeA) || 0) +
                                    (parseFloat(proformaData.otherIncomeB) || 0) +
                                    (parseFloat(proformaData.otherIncomeC) || 0);
                                const totalPreTaxIncome = totalFarmIncome + totalNonFarmIncome;
                                return totalPreTaxIncome.toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                });
                            })()}
                        </Typography>
                    </Grid>
                )}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}/>

                {/* Plus Adjustments */}
                <Grid item xs={12}>
                    <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>Plus Adjustments:</Typography>
                </Grid>
                {/* Farm Depreciation */}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">Farm Depreciation:</Typography>
                </Grid>
                {renderYearData('farmDepreciation')}
                {renderAverage('farmDepreciation')}
                {proforma && renderProforma('farmDepreciation')}

                {/* Non-Farm Depreciation */}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">Non-Farm Depreciation:</Typography>
                </Grid>
                {renderYearData('nonFarmDepreciation')}
                {renderAverage('nonFarmDepreciation')}
                {proforma && renderProforma('nonFarmDepreciation')}

                {/* Farm Current/Non-Current Int (F) */}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">Farm Current/Non-Current Int (F):</Typography>
                </Grid>
                {renderYearData('farmCurrentNonCurrentIntF')}
                {renderAverage('farmCurrentNonCurrentIntF')}
                {proforma && renderProforma('farmCurrentNonCurrentIntF')}

                {/* Non-Farm Current/Non-Current Int (E, E) */}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">Non-Farm Current/Non-Current Int (E, E):</Typography>
                </Grid>
                {renderYearData('nonFarmCurrentNonCurrentIntEE')}
                {renderAverage('nonFarmCurrentNonCurrentIntEE')}
                {proforma && renderProforma('nonFarmCurrentNonCurrentIntEE')}

                {/* Capital Lease Payments */}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">Capital Lease Payments:</Typography>
                </Grid>
                {renderYearData('capitalLeasePayments')}
                {renderAverage('capitalLeasePayments')}
                {proforma && renderProforma('capitalLeasePayments')}

                {/* Other Plus Adjustment */}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">Other Plus Adjustment:</Typography>
                </Grid>
                {renderYearData('otherPlusAdjustment')}
                {renderAverage('otherPlusAdjustment')}
                {proforma && renderProforma('otherPlusAdjustment')}

                {/* Minus Adjustments */}
                <Grid item xs={12}>
                    <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>Minus Adjustments:</Typography>
                </Grid>
                {/* Income & FICA taxes */}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">Income & FICA taxes:</Typography>
                </Grid>
                {renderYearData('incomeAndFICATaxes')}
                {renderAverage('incomeAndFICATaxes')}
                {proforma && renderProforma('incomeAndFICATaxes')}

                {/* Family Living */}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">Family Living:</Typography>
                </Grid>
                {renderYearData('familyLiving')}
                {renderAverage('familyLiving')}
                {proforma && renderProforma('familyLiving')}

                {/* Non-Recurring */}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">Non-Recurring:</Typography>
                </Grid>
                {renderYearData('nonRecurring')}
                {renderAverage('nonRecurring')}
                {proforma && renderProforma('nonRecurring')}

                {/* Other Minus Adjustments */}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1">Other Minus Adjustments:</Typography>
                </Grid>
                {renderYearData('otherMinusAdjustments')}
                {renderAverage('otherMinusAdjustments')}
                {proforma && renderProforma('otherMinusAdjustments')}

                {/* Total Cash Flow Available */}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>Total Cash Flow Available:</Typography>
                </Grid>
                {Array.from({length: selectedBorrower?.incomeAndExpenses?.years || 0}).map((_, index) => {
                    const yearIndex = selectedBorrower?.incomeAndExpenses?.years - 1 - index;
                    const data = Array.isArray(selectedBorrower?.incomeAndExpenses?.data) ? selectedBorrower.incomeAndExpenses.data.slice().reverse() : [];
                    const totalPreTaxIncome =
                        (parseFloat(data[yearIndex]?.farmIncome) || 0) +
                        (parseFloat(data[yearIndex]?.netPurchasedLS) || 0) +
                        (parseFloat(data[yearIndex]?.raisedFarmProducts) || 0) +
                        (parseFloat(data[yearIndex]?.cooperativeDistributions) || 0) +
                        (parseFloat(data[yearIndex]?.agProgramPayments) || 0) +
                        (parseFloat(data[yearIndex]?.cccLoans) || 0) +
                        (parseFloat(data[yearIndex]?.cropInsurance) || 0) +
                        (parseFloat(data[yearIndex]?.customHire) || 0) +
                        (parseFloat(data[yearIndex]?.farmOtherIncomeA) || 0) +
                        (parseFloat(data[yearIndex]?.farmOtherIncomeB) || 0) +
                        (parseFloat(data[yearIndex]?.salaries) || 0) +
                        (parseFloat(data[yearIndex]?.interestIncome) || 0) +
                        (parseFloat(data[yearIndex]?.dividends) || 0) +
                        (parseFloat(data[yearIndex]?.businessIncomeSchC) || 0) +
                        (parseFloat(data[yearIndex]?.capitalGainLossSchD) || 0) +
                        (parseFloat(data[yearIndex]?.otherGainLoss4797) || 0) +
                        (parseFloat(data[yearIndex]?.rentalIncomeSchE) || 0) +
                        (parseFloat(data[yearIndex]?.otherIncomeA) || 0) +
                        (parseFloat(data[yearIndex]?.otherIncomeB) || 0) +
                        (parseFloat(data[yearIndex]?.otherIncomeC) || 0);
                    const plusAdjustments =
                        (parseFloat(data[yearIndex]?.farmDepreciation) || 0) +
                        (parseFloat(data[yearIndex]?.nonFarmDepreciation) || 0) +
                        (parseFloat(data[yearIndex]?.farmCurrentNonCurrentIntF) || 0) +
                        (parseFloat(data[yearIndex]?.nonFarmCurrentNonCurrentIntEE) || 0) +
                        (parseFloat(data[yearIndex]?.capitalLeasePayments) || 0) +
                        (parseFloat(data[yearIndex]?.otherPlusAdjustment) || 0);
                    const minusAdjustments =
                        (parseFloat(data[yearIndex]?.incomeAndFICATaxes) || 0) +
                        (parseFloat(data[yearIndex]?.familyLiving) || 0) +
                        (parseFloat(data[yearIndex]?.nonRecurring) || 0) +
                        (parseFloat(data[yearIndex]?.otherMinusAdjustments) || 0);
                    const totalCashFlowAvailable = totalPreTaxIncome + plusAdjustments - minusAdjustments;

                    return (
                        <Grid item
                              xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}
                              key={`totalCashFlowAvailable-${index}`}>
                            <Typography variant="subtitle1"
                                        sx={{fontWeight: 'bold'}}>{totalCashFlowAvailable.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            })}</Typography>
                        </Grid>
                    );
                })}
                <Grid item
                      xs={12 / (proforma ? (selectedBorrower?.incomeAndExpenses?.years || 0) + 3 : (selectedBorrower?.incomeAndExpenses?.years || 0) + 2)}>
                    <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>
                        {
                            Array.isArray(selectedBorrower?.incomeAndExpenses?.data) ?
                                (
                                    selectedBorrower.incomeAndExpenses.data.slice(0, selectedBorrower?.incomeAndExpenses?.years || 0)
                                        .reduce((sum, year) => {
                                            const totalPreTaxIncome =
                                                (parseFloat(year?.farmIncome) || 0) +
                                                (parseFloat(year?.netPurchasedLS) || 0) +
                                                (parseFloat(year?.raisedFarmProducts) || 0) +
                                                (parseFloat(year?.cooperativeDistributions) || 0) +
                                                (parseFloat(year?.agProgramPayments) || 0) +
                                                (parseFloat(year?.cccLoans) || 0) +
                                                (parseFloat(year?.cropInsurance) || 0) +
                                                (parseFloat(year?.customHire) || 0) +
                                                (parseFloat(year?.farmOtherIncomeA) || 0) +
                                                (parseFloat(year?.farmOtherIncomeB) || 0) +
                                                (parseFloat(year?.salaries) || 0) +
                                                (parseFloat(year?.interestIncome) || 0) +
                                                (parseFloat(year?.dividends) || 0) +
                                                (parseFloat(year?.businessIncomeSchC) || 0) +
                                                (parseFloat(year?.capitalGainLossSchD) || 0) +
                                                (parseFloat(year?.otherGainLoss4797) || 0) +
                                                (parseFloat(year?.rentalIncomeSchE) || 0) +
                                                (parseFloat(year?.otherIncomeA) || 0) +
                                                (parseFloat(year?.otherIncomeB) || 0) +
                                                (parseFloat(year?.otherIncomeC) || 0);
                                            const plusAdjustments =
                                                (parseFloat(year?.farmDepreciation) || 0) +
                                                (parseFloat(year?.nonFarmDepreciation) || 0) +
                                                (parseFloat(year?.farmCurrentNonCurrentIntF) || 0) +
                                                (parseFloat(year?.nonFarmCurrentNonCurrentIntEE) || 0) +
                                                (parseFloat(year?.capitalLeasePayments) || 0) +
                                                (parseFloat(year?.otherPlusAdjustment) || 0);
                                            const minusAdjustments =
                                                (parseFloat(year?.incomeAndFICATaxes) || 0) +
                                                (parseFloat(year?.familyLiving) || 0) +
                                                (parseFloat(year?.nonRecurring) || 0) +
                                                (parseFloat(year?.otherMinusAdjustments) || 0);
                                            return sum + (totalPreTaxIncome + plusAdjustments - minusAdjustments);
                                        }, 0) / (selectedBorrower?.incomeAndExpenses?.years || 1) || 0
                                ).toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                }) : '$0.00'}
                    </Typography>
                </Grid>
                {proforma && (
                    <Grid item xs={12 / ((selectedBorrower?.incomeAndExpenses?.years || 0) + 3)}>
                        <Typography variant="subtitle1"
                                    sx={{fontWeight: 'bold'}}>
                            {(
                                (parseFloat(loanDetailStore.loanData.incomeAndExpensesProforma?.farmIncome) || 0) +
                                (parseFloat(loanDetailStore.loanData.incomeAndExpensesProforma?.netPurchasedLS) || 0) +
                                (parseFloat(loanDetailStore.loanData.incomeAndExpensesProforma?.raisedFarmProducts) || 0) +
                                (parseFloat(loanDetailStore.loanData.incomeAndExpensesProforma?.cooperativeDistributions) || 0) +
                                (parseFloat(loanDetailStore.loanData.incomeAndExpensesProforma?.agProgramPayments) || 0) +
                                (parseFloat(loanDetailStore.loanData.incomeAndExpensesProforma?.cccLoans) || 0) +
                                (parseFloat(loanDetailStore.loanData.incomeAndExpensesProforma?.cropInsurance) || 0) +
                                (parseFloat(loanDetailStore.loanData.incomeAndExpensesProforma?.customHire) || 0) +
                                (parseFloat(loanDetailStore.loanData.incomeAndExpensesProforma?.otherIncomeA) || 0) +
                                (parseFloat(loanDetailStore.loanData.incomeAndExpensesProforma?.otherIncomeB) || 0) +
                                (parseFloat(loanDetailStore.loanData.incomeAndExpensesProforma?.salaries) || 0) +
                                (parseFloat(loanDetailStore.loanData.incomeAndExpensesProforma?.interestIncome) || 0) +
                                (parseFloat(loanDetailStore.loanData.incomeAndExpensesProforma?.dividends) || 0) +
                                (parseFloat(loanDetailStore.loanData.incomeAndExpensesProforma?.businessIncomeSchC) || 0) +
                                (parseFloat(loanDetailStore.loanData.incomeAndExpensesProforma?.capitalGainLossSchD) || 0) +
                                (parseFloat(loanDetailStore.loanData.incomeAndExpensesProforma?.otherGainLoss4797) || 0) +
                                (parseFloat(loanDetailStore.loanData.incomeAndExpensesProforma?.rentalIncomeSchE) || 0) +
                                (parseFloat(loanDetailStore.loanData.incomeAndExpensesProforma?.otherIncomeA) || 0) +
                                (parseFloat(loanDetailStore.loanData.incomeAndExpensesProforma?.otherIncomeB) || 0) +
                                (parseFloat(loanDetailStore.loanData.incomeAndExpensesProforma?.otherIncomeC) || 0) +
                                (parseFloat(loanDetailStore.loanData.incomeAndExpensesProforma?.incomeFromNewProperty) || 0) +
                                (parseFloat(loanDetailStore.loanData.incomeAndExpensesProforma?.eliminationsOnConsolidation) || 0) +
                                (parseFloat(loanDetailStore.loanData.incomeAndExpensesProforma?.farmDepreciation) || 0) +
                                (parseFloat(loanDetailStore.loanData.incomeAndExpensesProforma?.nonFarmDepreciation) || 0) +
                                (parseFloat(loanDetailStore.loanData.incomeAndExpensesProforma?.farmCurrentNonCurrentIntF) || 0) +
                                (parseFloat(loanDetailStore.loanData.incomeAndExpensesProforma?.nonFarmCurrentNonCurrentIntEE) || 0) +
                                (parseFloat(loanDetailStore.loanData.incomeAndExpensesProforma?.capitalLeasePayments) || 0) +
                                (parseFloat(loanDetailStore.loanData.incomeAndExpensesProforma?.otherPlusAdjustment) || 0) -
                                (parseFloat(loanDetailStore.loanData.incomeAndExpensesProforma?.incomeAndFICATaxes) || 0) -
                                (parseFloat(loanDetailStore.loanData.incomeAndExpensesProforma?.familyLiving) || 0) -
                                (parseFloat(loanDetailStore.loanData.incomeAndExpensesProforma?.nonRecurring) || 0) -
                                (parseFloat(loanDetailStore.loanData.incomeAndExpensesProforma?.otherMinusAdjustments) || 0)
                            ).toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            })}
                        </Typography>
                    </Grid>
                )}
                {proforma && (
                    <>
                        <Grid item
                              xs={12 / ((selectedBorrower?.incomeAndExpenses?.years || 0) + 3)}>
                            <Typography variant="subtitle1">Total Annual P&I:</Typography>
                        </Grid>
                        {renderYearData('totalAnnualPI')}
                        {renderAverage('totalAnnualPI')}
                        {proforma && renderProforma('totalAnnualPI')}

                        {/* Annual Capital Lease Payments */}
                        <Grid item
                              xs={12 / ((selectedBorrower?.incomeAndExpenses?.years || 0) + 3)}>
                            <Typography variant="subtitle1">Annual Capital Lease Payments:</Typography>
                        </Grid>
                        {renderYearData('annualCapitalLeasePayments')}
                        {renderAverage('annualCapitalLeasePayments')}
                        {proforma && renderProforma('annualCapitalLeasePayments')}

                        {/* Total Required Cash Flow */}
                        <Grid item
                              xs={12 / ((selectedBorrower?.incomeAndExpenses?.years || 0) + 3)}>
                            <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>Total Required Cash
                                Flow:</Typography>
                        </Grid>
                        {Array.from({length: selectedBorrower?.incomeAndExpenses?.years || 0}).map((_, index) => {
                            const yearIndex = selectedBorrower?.incomeAndExpenses?.years - 1 - index;
                            const data = Array.isArray(selectedBorrower?.incomeAndExpenses?.data) ? selectedBorrower.incomeAndExpenses.data.slice().reverse() : [];
                            const totalRequiredCashFlow =
                                (parseFloat(data[yearIndex]?.totalAnnualPI) || 0) +
                                (parseFloat(data[yearIndex]?.annualCapitalLeasePayments) || 0);

                            return (
                                <Grid item
                                      xs={12 / ((selectedBorrower?.incomeAndExpenses?.years || 0) + 3)}
                                      key={`totalRequiredCashFlow-${index}`}>
                                    <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>
                                        {totalRequiredCashFlow.toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD'
                                        })}
                                    </Typography>
                                </Grid>
                            );
                        })}
                        {renderAverage('totalRequiredCashFlow')}
                        {proforma && renderProformaSum(['totalAnnualPI', 'annualCapitalLeasePayments'])}

                        {/* Excess Cash Available */}
                        <Grid item
                              xs={12 / ((selectedBorrower?.incomeAndExpenses?.years || 0) + 3)}>
                            <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>Excess Cash
                                Available:</Typography>
                        </Grid>
                        {Array.from({length: selectedBorrower?.incomeAndExpenses?.years || 0}).map((_, index) => {
                            const yearIndex = selectedBorrower?.incomeAndExpenses?.years - 1 - index;
                            const data = Array.isArray(selectedBorrower?.incomeAndExpenses?.data) ? selectedBorrower.incomeAndExpenses.data.slice().reverse() : [];
                            const totalCashFlowAvailable = calculateTotalCashFlowAvailable(data[yearIndex]);
                            const totalRequiredCashFlow =
                                (parseFloat(data[yearIndex]?.totalAnnualPI) || 0) +
                                (parseFloat(data[yearIndex]?.annualCapitalLeasePayments) || 0);
                            const excessCashAvailable = totalCashFlowAvailable - totalRequiredCashFlow;

                            return (
                                <Grid item
                                      xs={12 / ((selectedBorrower?.incomeAndExpenses?.years || 0) + 3)}
                                      key={`excessCashAvailable-${index}`}>
                                    <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>
                                        {excessCashAvailable.toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD'
                                        })}
                                    </Typography>
                                </Grid>
                            );
                        })}
                        {renderAverage('excessCashAvailable')}
                        {proforma && (
                            <Grid item xs={12 / ((selectedBorrower?.incomeAndExpenses?.years || 0) + 3)}>
                                <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>
                                    {calculateProformaExcessCashAvailable().toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD'
                                    })}
                                </Typography>
                            </Grid>
                        )}

                        {/* ProForma Total Debt Coverage */}
                        <Grid item
                              xs={12 / ((selectedBorrower?.incomeAndExpenses?.years || 0) + 3)}>
                            <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>ProForma Total Debt
                                Coverage:</Typography>
                        </Grid>
                        {Array.from({length: selectedBorrower?.incomeAndExpenses?.years || 0}).map((_, index) => {
                            const yearIndex = selectedBorrower?.incomeAndExpenses?.years - 1 - index;
                            const data = Array.isArray(selectedBorrower?.incomeAndExpenses?.data) ? selectedBorrower.incomeAndExpenses.data.slice().reverse() : [];
                            const totalCashFlowAvailable = calculateTotalCashFlowAvailable(data[yearIndex]);
                            const totalRequiredCashFlow =
                                (parseFloat(data[yearIndex]?.totalAnnualPI) || 0) +
                                (parseFloat(data[yearIndex]?.annualCapitalLeasePayments) || 0);
                            const debtCoverage = totalRequiredCashFlow !== 0 ? totalCashFlowAvailable / totalRequiredCashFlow : 0;

                            return (
                                <Grid item
                                      xs={12 / ((selectedBorrower?.incomeAndExpenses?.years || 0) + 3)}
                                      key={`debtCoverage-${index}`}>
                                    <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>
                                        {debtCoverage.toFixed(2)}
                                    </Typography>
                                </Grid>
                            );
                        })}
                        {renderAverage('proFormaDebtCoverage')}
                        {proforma && (
                            <Grid item xs={12 / ((selectedBorrower?.incomeAndExpenses?.years || 0) + 3)}>
                                <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>
                                    {calculateProformaDebtCoverage().toFixed(2)}
                                </Typography>
                            </Grid>
                        )}
                        {/* TDC Shock 3% */}
                        <Grid item
                              xs={12 / ((selectedBorrower?.incomeAndExpenses?.years || 0) + 3)}>
                            <Typography variant="subtitle1">TDC Shock 3%:</Typography>
                        </Grid>
                        {Array.from({length: selectedBorrower?.incomeAndExpenses?.years || 0}).map((_, index) => {
                            const yearIndex = selectedBorrower?.incomeAndExpenses?.years - 1 - index;
                            const data = Array.isArray(selectedBorrower?.incomeAndExpenses?.data) ? selectedBorrower.incomeAndExpenses.data.slice().reverse() : [];
                            const cashFlowAvailable = calculateTotalCashFlowAvailable(data[yearIndex]);
                            const totalRequiredCashFlow =
                                (parseFloat(data[yearIndex]?.totalAnnualPI) || 0) +
                                (parseFloat(data[yearIndex]?.annualCapitalLeasePayments) || 0);
                            const tdcShock3 = totalRequiredCashFlow !== 0 ? (cashFlowAvailable * 0.97) / totalRequiredCashFlow : 0;

                            return (
                                <Grid item
                                      xs={12 / ((selectedBorrower?.incomeAndExpenses?.years || 0) + 3)}
                                      key={`tdcShock3-${index}`}>
                                    <Typography variant="subtitle1">
                                        {tdcShock3.toFixed(2)}
                                    </Typography>
                                </Grid>
                            );
                        })}
                        {renderAverage('tdcShock3')}
                        {proforma && (
                            <Grid item xs={12 / ((selectedBorrower?.incomeAndExpenses?.years || 0) + 3)}>
                                <Typography variant="subtitle1">
                                    {calculateProformaTdcShock(0.97).toFixed(2)}
                                </Typography>
                            </Grid>
                        )}

                        {/* TDC Shock 5% */}
                        <Grid item
                              xs={12 / ((selectedBorrower?.incomeAndExpenses?.years || 0) + 3)}>
                            <Typography variant="subtitle1">TDC Shock 5%:</Typography>
                        </Grid>
                        {Array.from({length: selectedBorrower?.incomeAndExpenses?.years || 0}).map((_, index) => {
                            const yearIndex = selectedBorrower?.incomeAndExpenses?.years - 1 - index;
                            const data = Array.isArray(selectedBorrower?.incomeAndExpenses?.data) ? selectedBorrower.incomeAndExpenses.data.slice().reverse() : [];
                            const cashFlowAvailable = calculateTotalCashFlowAvailable(data[yearIndex]);
                            const totalRequiredCashFlow =
                                (parseFloat(data[yearIndex]?.totalAnnualPI) || 0) +
                                (parseFloat(data[yearIndex]?.annualCapitalLeasePayments) || 0);
                            const tdcShock5 = totalRequiredCashFlow !== 0 ? (cashFlowAvailable * 0.95) / totalRequiredCashFlow : 0;

                            return (
                                <Grid item
                                      xs={12 / ((selectedBorrower?.incomeAndExpenses?.years || 0) + 3)}
                                      key={`tdcShock5-${index}`}>
                                    <Typography variant="subtitle1">
                                        {tdcShock5.toFixed(2)}
                                    </Typography>
                                </Grid>
                            );
                        })}
                        {renderAverage('tdcShock5')}
                        {proforma && (
                            <Grid item xs={12 / ((selectedBorrower?.incomeAndExpenses?.years || 0) + 3)}>
                                <Typography variant="subtitle1">
                                    {calculateProformaTdcShock(0.95).toFixed(2)}
                                </Typography>
                            </Grid>
                        )}
                    </>
                )}
                {/* Comments */}
                <Grid item xs={12}>
                    <TextField
                        label="Comments"
                        multiline
                        rows={4}
                        value={selectedBorrower?.incomeAndExpenses?.comments || ''}
                        onChange={(event) => updateIncomeAndExpenses(selectedBorrowerId, null, 'comments', event.target.value)}
                        fullWidth
                    />
                </Grid>
            </Grid>
        </Box>
    );
});

export default IncomeAndExpensesBase;
