import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Typography, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import loanDetailStore from '../store';

const ConsolidatedIncomeExpenseProforma = observer(() => {
    const { loanData } = loanDetailStore;
    const proformaData = loanData?.incomeAndExpensesProforma || {};

    const formatCurrency = (value) => {
        return value ? `$${parseFloat(value).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}` : '$0.00';
    };

    const renderRow = (label, field, isTotal = false) => {
        const value = proformaData[field] || 0;
        return (
            <TableRow>
                <TableCell>{label}</TableCell>
                <TableCell align="right">
                    <Typography variant={isTotal ? "subtitle1" : "body2"} fontWeight={isTotal ? "bold" : "normal"}>
                        {formatCurrency(value)}
                    </Typography>
                </TableCell>
            </TableRow>
        );
    };

    // const calculateTotalFarmIncome = () => {
    //     return [
    //         'netPurchasedLS', 'raisedFarmProducts', 'cooperativeDistributions', 'agProgramPayments',
    //         'cccLoans', 'cropInsurance', 'customHire', 'otherIncomeA', 'otherIncomeB', 'incomeFromNewProperty'
    //     ].reduce((sum, field) => sum + (parseFloat(proformaData[field]) || 0), 0);
    // };
    //
    // const calculateTotalFarmExpense = () => {
    //     return [
    //         'carAndTruck', 'chemicals', 'conservation', 'customHire', 'depreciation', 'employeeBenefit',
    //         'feed', 'fertilizer', 'freight', 'gasolineFuel', 'insurance', 'interestOtherCurrent',
    //         'interestMortgageNonCurrent', 'labor', 'rentLeaseMachinery', 'rentLeaseRealProperty',
    //         'repairs', 'seed', 'storage', 'supplies', 'taxes', 'utilities', 'veterinaryFees',
    //         'otherExpensesA', 'otherExpensesB', 'expenseFromNewProperty'
    //     ].reduce((sum, field) => sum + (parseFloat(proformaData[field]) || 0), 0);
    // };
    //
    // const calculateTotalNonFarmIncome = () => {
    //     return [
    //         'salaries', 'interestIncome', 'dividends', 'businessIncomeSchC', 'capitalGainLossSchD',
    //         'otherGainLoss4797', 'rentalIncomeSchE', 'otherIncomeA', 'otherIncomeB', 'otherIncomeC'
    //     ].reduce((sum, field) => sum + (parseFloat(proformaData[field]) || 0), 0);
    // };

    // const calculateCashFlowAvailable = () => {
    //     const totalPreTaxIncome = calculateTotalFarmIncome() + calculateTotalNonFarmIncome() - calculateTotalFarmExpense();
    //     const plusAdjustments = [
    //         'farmDepreciation', 'farmCurrentNonCurrentIntF', 'nonFarmDepreciation', 'nonFarmCurrentNonCurrentIntEE',
    //         'capitalLeasePayments', 'otherPlusAdjustment'
    //     ].reduce((sum, field) => sum + (parseFloat(proformaData[field]) || 0), 0);
    //     const minusAdjustments = [
    //         'incomeAndFICATaxes', 'familyLiving', 'nonRecurring', 'otherMinusAdjustments'
    //     ].reduce((sum, field) => sum + (parseFloat(proformaData[field]) || 0), 0);
    //     return totalPreTaxIncome + plusAdjustments - minusAdjustments;
    // };

    return (
        <Box mb={3}>
            <Typography variant="h6" gutterBottom>Consolidated Income and Expense Proforma</Typography>
            <Typography variant="subtitle2" gutterBottom>Print Date: {new Date().toLocaleDateString()}</Typography>

            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Item</TableCell>
                        <TableCell align="right">Pro Forma</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell colSpan={2}><Typography variant="subtitle1">Farm Income</Typography></TableCell>
                    </TableRow>
                    {renderRow('Net Purchased LS & Other', 'netPurchasedLS')}
                    {renderRow('Raised Farm Products', 'raisedFarmProducts')}
                    {renderRow('Cooperative Distributions', 'cooperativeDistributions')}
                    {renderRow('Ag Program Payments', 'agProgramPayments')}
                    {renderRow('CCC Loans', 'cccLoans')}
                    {renderRow('Crop Insurance', 'cropInsurance')}
                    {renderRow('Custom Hire', 'customHire')}
                    {renderRow('Other Income A', 'otherIncomeA')}
                    {renderRow('Other Income B', 'otherIncomeB')}
                    {renderRow('Income from New Property', 'incomeFromNewProperty')}
                    {renderRow('Elimination on Consolidation', 'eliminationsOnConsolidation')}
                    {renderRow('Total Farm Income', 'totalFarmIncome', true)}

                    <TableRow>
                        <TableCell colSpan={2}><Typography variant="subtitle1">Farm Expenses</Typography></TableCell>
                    </TableRow>
                    {renderRow('Car & Truck', 'carAndTruck')}
                    {renderRow('Chemicals', 'chemicals')}
                    {renderRow('Conservation', 'conservation')}
                    {renderRow('Custom Hire', 'customHire')}
                    {renderRow('Depreciation', 'depreciation')}
                    {renderRow('Employee Benefit', 'employeeBenefit')}
                    {renderRow('Feed', 'feed')}
                    {renderRow('Fertilizer', 'fertilizer')}
                    {renderRow('Freight', 'freight')}
                    {renderRow('Gasoline, Fuel', 'gasolineFuel')}
                    {renderRow('Insurance', 'insurance')}
                    {renderRow('Interest - Other Interest/Current', 'interestOtherCurrent')}
                    {renderRow('Interest - Mortgage/Non-Current', 'interestMortgageNonCurrent')}
                    {renderRow('Labor', 'labor')}
                    {renderRow('Rent/Lease, Machinery', 'rentLeaseMachinery')}
                    {renderRow('Rent/Lease, Real Property', 'rentLeaseRealProperty')}
                    {renderRow('Repairs', 'repairs')}
                    {renderRow('Seed', 'seed')}
                    {renderRow('Storage', 'storage')}
                    {renderRow('Supplies', 'supplies')}
                    {renderRow('Taxes', 'taxes')}
                    {renderRow('Utilities', 'utilities')}
                    {renderRow('Veterinary Fees', 'veterinaryFees')}
                    {renderRow('Other Exp A', 'otherExpensesA')}
                    {renderRow('Other Exp B', 'otherExpensesB')}
                    {renderRow('Expense from New Property', 'expenseFromNewProperty')}
                    {renderRow('Elimination on Consolidation', 'eliminationOnConsolidation')}
                    {renderRow('Total Farm Expense', 'totalFarmExpense', true)}

                    {renderRow('Net Farm Income', 'netFarmIncome', true)}

                    <TableRow>
                        <TableCell colSpan={2}><Typography variant="subtitle1">Non-Farm Income</Typography></TableCell>
                    </TableRow>
                    {renderRow('Salaries', 'salaries')}
                    {renderRow('Interest Income', 'interestIncome')}
                    {renderRow('Dividends', 'dividends')}
                    {renderRow('Business Income (Sch C)', 'businessIncomeSchC')}
                    {renderRow('Capital Gain/Loss (Sch D)', 'capitalGainLossSchD')}
                    {renderRow('Other Gains/Loss (4797)', 'otherGainLoss4797')}
                    {renderRow('Rental Income (Sch E)', 'rentalIncomeSchE')}
                    {renderRow('Other Inc A', 'otherIncomeA')}
                    {renderRow('Other Inc B', 'otherIncomeB')}
                    {renderRow('Other Inc C', 'otherIncomeC')}
                    {renderRow('Total Non-Farm Income', 'totalNonFarmIncome', true)}

                    {renderRow('Total Pre-Tax Income', 'totalPreTaxIncome', true)}

                    <TableRow>
                        <TableCell colSpan={2}><Typography variant="subtitle1">Cash Flow Coverage</Typography></TableCell>
                    </TableRow>
                    {renderRow('Total Pre-Tax Income', 'totalPreTaxIncome')}
                    <TableRow>
                        <TableCell colSpan={2}><Typography variant="subtitle2">Plus Adjustments:</Typography></TableCell>
                    </TableRow>
                    {renderRow('Farm Depreciation', 'farmDepreciation')}
                    {renderRow('Farm Current/Non-Current Inc (F)', 'farmCurrentNonCurrentIntF')}
                    {renderRow('Non-Farm Depreciation', 'nonFarmDepreciation')}
                    {renderRow('Non-Farm Non-Curr. Interest (C, E)', 'nonFarmCurrentNonCurrentIntEE')}
                    {renderRow('Capital Lease Payments', 'capitalLeasePayments')}
                    {renderRow('Other Plus Adj', 'otherPlusAdjustment')}
                    <TableRow>
                        <TableCell colSpan={2}><Typography variant="subtitle2">Minus Adjustments:</Typography></TableCell>
                    </TableRow>
                    {renderRow('Income & FICA Taxes', 'incomeAndFICATaxes')}
                    {renderRow('Family Living', 'familyLiving')}
                    {renderRow('Non-Recurring', 'nonRecurring')}
                    {renderRow('Other Minus Adj', 'otherMinusAdjustments')}
                    {renderRow('Cash Flow Available', 'cashFlowAvailable', true)}

                    {renderRow('Total Annual P&I', 'totalAnnualPI')}
                    {renderRow('Annual Capital Lease Payments', 'annualCapitalLeasePayments')}
                    {renderRow('Total Required Cash Flow', 'totalRequiredCashFlow', true)}
                    {renderRow('Excess Cash Available', 'excessCashAvailable', true)}
                    {renderRow('ProForma Total Debt Coverage', 'proFormaTotalDebtCoverage', true)}
                    {renderRow('TDC Shock 3%', 'tdcShock3')}
                    {renderRow('TDC Shock 5%', 'tdcShock5')}
                </TableBody>
            </Table>
        </Box>
    );
});

export default ConsolidatedIncomeExpenseProforma;